import React, { useEffect } from 'react'

import CommentView from 'components/CommentView'
import ImgView from 'components/ImgView'

import DetailReviewButtons from './DetailReviewButtons'
import styles from './DetailReviewForm.module.scss'

export default function DetailReviewForm({
  getReviewData,
  reviewData,
  isLoading,
  isMyPage = false // 마이페이지 > 수강후기 상세: 버튼 영역 날짜 노출 X
}) {
  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ')
      return getDate[0]
    } else {
      return null
    }
  }

  useEffect(() => {
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [reviewData])

  return (
    <>
      {reviewData && (
        <div className={styles.detailView}>
          <div className={styles.flexBox}>
            <div className={styles.leftBox}>
              <span
                className={styles.thumbSpan}
                style={{
                  backgroundImage: reviewData.profileUrl && `url(${reviewData.profileUrl})`
                }}
              >
                <i>썸네일</i>
              </span>
              <div>
                <strong>
                  <span>{reviewData.nickName ? reviewData.nickName : reviewData.username}</span>
                  님의 후기
                </strong>
              </div>
            </div>
            <div className={styles.rightBox}>
              {reviewData.satisfaction && (
                <div className={styles.starPoint}>
                  <IconStar /> 만족도 <strong>{reviewData.satisfaction.toFixed(1)}</strong>
                </div>
              )}
            </div>
          </div>

          {/* 후기 텍스트 */}
          <div className={styles.contents}>
            <p>{reviewData.contents}</p>
          </div>

          {/* 후기 대상 */}
          <div className={styles.reviewItem}>
            <p>{reviewData.title ? reviewData.title : '장학금 후기'}</p>
          </div>

          {/* 콘텐츠 이미지 목록 */}
          <ImgView data={reviewData} isLoading={isLoading} />

          {/* 좋아요/공유/댓글/날짜 */}
          <DetailReviewButtons type="REAL" isShowDate={!isMyPage} data={reviewData} />

          <p className={`${styles.commentTotal} ${!reviewData.answer ? styles.hasNoComment : ''}`}>
            {reviewData.commentCount}개의 댓글
          </p>

          {/* 학습 관리자 댓글 */}
          {reviewData.answerYn === 'Y' && reviewData.answer && (
            <div className={styles.adminAnswer}>
              <div className={styles.thumbnail}>
                <i className="blind">썸네일</i>
                {reviewData.answer.answerThumbnailPath && (
                  <img
                    src="https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_admin_thumbnail.png"
                    alt="학습 관리자"
                  />
                )}
                <IconAdmin />
              </div>

              <div className={styles.answer}>
                <div className={styles.answerBox}>
                  <p className={styles.writer}>{reviewData.answer.answerUserName}</p>
                  <pre
                    className={styles.answerTxt}
                    dangerouslySetInnerHTML={{
                      __html: reviewData.answer.answerContent.replaceAll('\n', '')
                    }}
                  />
                </div>
                <p className={styles.date}>
                  {formatDate(reviewData.answer.answerDate).replaceAll('-', '.')}
                </p>
              </div>
            </div>
          )}

          {/* 유저 댓글 */}
          <CommentView getReviewData={getReviewData} data={reviewData} isLoading={isLoading} />
        </div>
      )}
    </>
  )
}

function IconStar() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 1.7998L15.5691 8.05891L22.5 9.59191L17.775 14.9933L18.4894 22.1998L12 19.2789L5.51064 22.1998L6.225 14.9933L1.5 9.59191L8.43085 8.05891L12 1.7998Z"
        fill="#FF6B00"
      />
    </svg>
  )
}

function IconAdmin() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_203_14340)">
        <circle cx="8" cy="8" r="5" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0C12.4183 0 16 3.58173 16 8C16 12.4183 12.4183 16 8 16C3.58173 16 0 12.4183 0 8C0 3.58173 3.58173 0 8 0ZM4.25533 8.47497L6.53373 10.5673C6.8661 10.8732 7.38053 10.8569 7.69303 10.5345L11.7576 6.6429C12.0882 6.32487 12.0984 5.79903 11.7804 5.46847C11.4624 5.13787 10.9366 5.12767 10.606 5.44567L7.08513 8.81667L5.381 7.25167C5.0432 6.94083 4.51737 6.96267 4.20653 7.30047C3.8957 7.6383 3.91753 8.16413 4.25533 8.47497Z"
          fill="#78A0DC"
        />
        <circle cx="8" cy="8" r="7.5" stroke="white" />
      </g>
      <defs>
        <clipPath id="clip0_203_14340">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
