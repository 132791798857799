import React from 'react'

import DotLottiePlayer from 'components/lottie/DotLottiePlayer'
import ResponsiveImage from 'components/ResponsiveImage'

import Modal from './Modal'
import s from './ModalTalkShare.module.css'
import { metaValues } from '../config'
import ShareBand from './share/ShareBand'
import ShareCopy from './share/ShareCopy'
import ShareKakao from './share/ShareKakao'

export default function ModalTalkShare({ close }) {
  return (
    <Modal onClose={close}>
      <article className={s.aiShare}>
        <hgroup className={s.header}>
          <h2>공유하기</h2>
          <button onClick={close}>close</button>
        </hgroup>
        <div className={s.scroll}>
          <section className={s.title}>
            <ResponsiveImage
              pc={'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/share_title_badge.png'}
              mo={
                'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/m_share_title_badge.png'
              }
              alt={'나만의 AI원어민 친구'}
              maxWidth={750}
            />
            <h3>
              내 친구에게 어울리는
              <br />
              <span>AI 원어민</span>은?
            </h3>
            <div className={s.animationBox}>
              <DotLottiePlayer dataName="ai_friends" dataType="lottie" />
            </div>
          </section>
          <section className={s.subText}>
            <dl>
              <dt>
                <ResponsiveImage
                  pc={'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/badge_subtext.png'}
                  mo={
                    'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/m_badge_subtext.png'
                  }
                  alt={'AI 야나두만의 혜택'}
                  maxWidth={750}
                />
                우린 찐친 같은 사이니까
                <br />
                틀려도 괜찮아!😉
              </dt>
              <dd>
                영어가 완벽하지 않아도 괜찮아요. AI 야나두는 표현이 틀려도, 문법이 완벽하지 않아도
                자연스러운 대화가 가능합니다. 여행, 음식, 스포츠 등 공통 관심사로 대화를 시작해
                보세요. 지루할 틈 없이 영어로 대화하는 재미에 빠지게 될 거예요.
              </dd>
              <dt>
                AI 친구니까! 부담은🙅🏻‍♂️
                <br />
                가볍게 톡하듯이💬
              </dt>
              <dd>
                원어민과 대화할 때 늘 긴장하진 않으셨나요? 부담 갖지 말고, 눈치 보지 말고 AI 친구와
                언제 어디서나 가볍게 톡 하듯이 대화해 보세요! 대화하다 보면 실생활에서 사용하는
                원어민의 리액션, 말투, 표현을 자연스럽게 익힐 수 있을 거예요.
              </dd>
            </dl>
          </section>
        </div>
        <div className={s.footer}>
          <div className={s.btnGroup}>
            <ShareCopy className={s.copy} pathname={'/AInativespeakertalk'} />
            <ShareKakao className={s.kakao} meta={metaValues} pathname={'/AInativespeakertalk'} />
          </div>
          <ShareBand className={s.band} meta={metaValues} pathname={'/AInativespeakertalk'} />
        </div>
      </article>
    </Modal>
  )
}
