export const getCurrentKSTDateTime = () => {
  const now = new Date()

  // YYYY-MM-DD 형식 변환 (공백 제거 포함)
  const datePart = now
    .toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'Asia/Seoul'
    })
    .replace(/\.\s/g, '-') // '2025. 01. 31.' → '2025-01-31'
    .replace(/\.$/, '') // 마지막 마침표 제거

  // HH:mm 형식 변환 (24시간제)
  const timePart = now
    .toLocaleTimeString('ko-KR', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'Asia/Seoul'
    })
    .replace(/\s/g, '') // 공백 제거

  return `${datePart}T${timePart}`
}
