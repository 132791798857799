import { useEffect, useState } from 'react'

import { YANADOO_BLOCKED_IP } from 'common/config' // 폐쇄망 IP

export default function useFetchIpAddress() {
  const excludedIp = localStorage.getItem('Excluded_IP')
  const [isBlocked, setIsBlocked] = useState(false)

  const handleIsExcluded = (currentIp) => {
    const isExcluded = YANADOO_BLOCKED_IP.includes(currentIp)

    localStorage.setItem('Excluded_IP', isExcluded ? 'Y' : 'N')
    setIsBlocked(isExcluded)
  }

  useEffect(() => {
    if (excludedIp) {
      setIsBlocked(excludedIp === 'Y')
      return
    }

    const fetchIp = async () => {
      try {
        const response = await fetch('https://api.ipify.org/?format=json')
        if (!response.ok) throw new Error('Failed to fetch IP')
        const data = await response.json()
        handleIsExcluded(data.ip)
      } catch (err) {
        console.log('Failed to fetch IP(error catch)', err.message)
        // IP 체크 실패 시 기본값 설정
        localStorage.setItem('Excluded_IP', 'N')
        setIsBlocked(false)
      }
    }

    fetchIp()
  }, [excludedIp])

  return { isBlocked, excludedIp }
}
