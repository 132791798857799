import { Link } from 'react-router-dom'

import { postGAEvent } from 'utils/postGAEvent'

import DynamicLink from 'components/DynamicLink'
import useHeaderStore from 'store/useHeaderStore'

import styles from '../../styles/SideMenu.module.scss'

export default function HeaderEventButton() {
  const gnbEventItems = useHeaderStore((state) => state.gnbEventItems)

  const onClickLink = (gaItem) => {
    postGAEvent(gaItem, gaItem)
  }

  return (
    <div className={styles.buttonEventContainer}>
      <Link to={'/event/list/1'} className={styles.buttonEvent}>
        이벤트
      </Link>
      {gnbEventItems?.length > 0 && (
        <ul>
          {gnbEventItems?.map((item, idx) => (
            <li key={idx}>
              <DynamicLink
                pathname={item.link}
                target={`_${item.linkTarget.toLowerCase()}`}
                onClick={() => onClickLink(item.gaEventName)}
              >
                {item.menuName}
              </DynamicLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
