import { BrowserView, MobileView } from 'react-device-detect'

import MetaInfo from 'components/common/MetaInfo'

import StepAnalyzing from './components/StepAnalyzing'
import StepConsultation from './components/StepConsultation'
import StepExam from './components/StepExam'
import StepNote from './components/StepNote'
import StepSelectType from './components/StepSelectType'
import StepSurvey from './components/StepSurvey'

import { levelTestAiValues } from './config'
import LevelTestAiStart from '../start'
import useLevelTestAi from './hooks/useLevelTestAi'
import styles from './styles/Exam.module.scss'

export default function LevelTestAiExam() {
  const {
    step,
    survey,
    onClickType,
    onClickBackType,
    onClickCancelNote,
    onClickConfirmNote,
    onQuitExam,
    onCompleteExam,
    onCompleteSurvey,
    onCompleteAnalyzing,
    onClickMoveToMain,
    onFailLoadAvatar
  } = useLevelTestAi()

  return (
    <>
      <MetaInfo props={levelTestAiValues.metaInfo} />
      <MobileView>
        <div className={styles.examContainer}>
          {
            {
              selectType: (
                <StepSelectType onClickBack={onClickBackType} onClickType={onClickType} />
              ), //테스트타입선택
              note: (
                <StepNote
                  onClickCancel={onClickCancelNote}
                  onClickConfirm={onClickConfirmNote}
                  onClickMoveToMain={onClickMoveToMain}
                />
              ), //유의사항
              exam: (
                <StepExam
                  onFailLoadAvatar={onFailLoadAvatar}
                  onQuitExam={onQuitExam}
                  onCompleteExam={onCompleteExam}
                />
              ), //테스트
              survey: <StepSurvey onCompleteSurvey={onCompleteSurvey} />, //설문
              analyzing: <StepAnalyzing onCompleteAnalyzing={onCompleteAnalyzing} />, //분석중
              consultation: <StepConsultation survey={survey} /> //상담신청
            }[step]
          }
        </div>
      </MobileView>
      <BrowserView>
        <LevelTestAiStart />
      </BrowserView>
    </>
  )
}
