import { useMemo } from 'react'

import ModalPortal from 'ModalPortal'

import { getCookie } from 'common/Cookie'
import ModalContainer from 'components/modal/modalContainer'
import ModalNotice from 'components/modal/ModalNotice'
import { LevelTestModalMarketing } from 'components/promotion/dbCollectionForm/components/LevelTestModalMarketing'
import useDbCollectionFormLevelTest from 'components/promotion/dbCollectionForm/hooks/useDbCollectionFormLevelTest'
import ModalCounselComplete from 'components/promotion/modal/ModalCounselComplete'
import ModalCounselDuplicated from 'components/promotion/modal/ModalCounselDuplicated'
import ModalWrap from 'components/promotion/modal/ModalWrap'

import { levelTestAdvancedValues } from 'pages/levelTestAdvanced/start/config'

import styles from './AnalyzingSection.module.scss'

export default function AnalyzingCompleteModal({ open, contents, onClickResult }) {
  const {
    formData, // 상담 폼 데이터 (개인정보 & 마케팅 동의)
    modalControl,
    handleModalToggle,
    isOpenModalNotice, // 이용 약관 모달
    handleToggleModalNotice,
    isOpenModalMarketing, // 마케팅 동의 체크 유도 모달
    handleToggleModalMarketing,
    isAllChecked, // 전체 동의 체크 유무
    handleAllCheckboxChange,
    handleCheckboxChange, // 개인정보 수집 및 이용 동의 체크 핸들러
    handleSubmit // 상담 신청 버튼 클릭 핸들러 (유효성 체크)
  } = useDbCollectionFormLevelTest()

  // 빠른 상담 예약 데이터
  const submitData = useMemo(() => {
    const data = {
      category: 'levelTest3',
      content: JSON.stringify(contents)
    }

    return data
  }, [contents])

  const handleMarketingSubmit = (smsAgreeYn) => {
    handleSubmit({ ...submitData, smsAgreeYn: smsAgreeYn ? 'Y' : 'N' }, true)
  }

  /**
   * 상담 신청하기 클릭
   */
  const handleClickSubmit = (e) => {
    e.preventDefault()
    const is = getCookie(levelTestAdvancedValues.marketingCookitName)
    handleSubmit(submitData, is === 'Y', onClickResult)
  }

  return (
    <>
      <ModalContainer open={open}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <img
              src="https://english.yanadoocdn.com/upload/yanadoo/new/levelTest/img_result-coupon_v2.svg"
              alt="할인혜택 최대 100,000원"
            />
          </div>
          <div className={styles.modalBody}>
            <p className={styles.modalTitle}>
              레벨테스트 결과가
              <br />
              도착했어요!
            </p>
            <p className={styles.modalDescription}>
              전문가와 전화로 상담하기를 신청하시면 <br />
              전문 학습 가이드가 추가로 할인받을 수 있는 혜택과 <br />
              경품에 대해 설명드려요 😊
            </p>
            <p className={styles.modalDescription2}>
              레벨테스트 완료 기념! 전화상담 후 구매시 시크릿 10만원 할인 혜택
            </p>
            <button
              className={`${styles.modalButton} ${styles.modalButtonHighlight}`}
              onClick={handleClickSubmit}
            >
              레벨테스트 결과 확인
              <p className={styles.modalButtonDescription}>+ 전문가와 상품 추천 무료로 상담받기</p>
            </button>
            <button
              type="button"
              className={`${styles.modalButton} ${styles.modalButtonHighlight}`}
              onClick={onClickResult}
            >
              <p>레벨테스트 결과만 보기</p>
            </button>

            <ul className={styles.agree}>
              <li>
                <label>
                  <input
                    type="checkbox"
                    id="allAgree"
                    checked={isAllChecked}
                    onChange={handleAllCheckboxChange}
                  />
                  <em></em>
                  전체 동의
                </label>
              </li>
              <li>
                <label>
                  <input
                    type="checkbox"
                    id="privacyAgreeYn"
                    checked={formData.privacyAgreeYn}
                    onChange={handleCheckboxChange}
                  />
                  <em></em>
                  개인정보 수집 · 이용 동의 [필수]
                  <button type="button" onClick={handleToggleModalNotice}>
                    약관보기
                  </button>
                </label>
              </li>
              <li>
                <label>
                  <input
                    type="checkbox"
                    id="smsAgreeYn"
                    checked={formData.smsAgreeYn}
                    onChange={handleCheckboxChange}
                  />
                  <em></em>
                  SMS 광고 및 마케팅 이용 동의 [선택]
                </label>
              </li>
            </ul>
          </div>
        </div>
      </ModalContainer>

      {/* 개인정보 동의 팝업 */}
      {isOpenModalNotice && (
        <ModalPortal>
          <ModalNotice
            title={'개인정보 수집 및 이용 동의 약관'}
            btnClose
            handleCloseModal={handleToggleModalNotice}
          />
        </ModalPortal>
      )}

      {/* 마케팅 수집 동의 모달 */}
      {isOpenModalMarketing && (
        <ModalWrap onClose={handleToggleModalMarketing}>
          <LevelTestModalMarketing
            cookieName={levelTestAdvancedValues.marketingCookitName}
            onMarketingSubmit={handleMarketingSubmit}
          />
        </ModalWrap>
      )}
      {modalControl.success && (
        <ModalCounselComplete
          buttonBackground="#000"
          buttonColor="#fff"
          onClose={() => {
            handleModalToggle('success')
            onClickResult()
          }}
        />
      )}

      {modalControl.duplicate && (
        <ModalCounselDuplicated
          buttonBackground="#000"
          buttonColor="#fff"
          onClose={() => {
            handleModalToggle('duplicate')
            onClickResult()
          }}
        />
      )}
    </>
  )
}
