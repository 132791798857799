import React, { useState, useCallback, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

import ModalPortal from 'ModalPortal'

import api from 'common/api'
import * as config from 'common/config'
import MobileHeader from 'components/common/MobileHeader'
import DetailReviewForm from 'components/DetailReviewForm'
import AlertModal from 'components/modal/AlertModal'
import ModalReviewWrite from 'components/modal/ModalReviewWrite'

import styles from './DetailReview.module.scss'
import Lnb from '../../../components/Lnb'

export default function DetailReview() {
  const navigate = useNavigate()
  const { learningReviewSeq } = useParams()
  const [reviewData, setReviewData] = useState([])
  const [isLoading, setIsLoading] = useState([])
  const [modalopenAlert, setModalopenAlert] = useState(false)
  const [modalopenReview, setModalopenReview] = useState(false)

  const handleOpenModalAlert = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenAlert(true)
  }, [])

  const handleCloseModalAlert = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenAlert(false)
  }, [])

  const handleOpenModalReview = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenReview(true)
  }, [])

  const handleCloseModalReview = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenReview(false)
  }, [])

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ')
      return getDate[0]
    } else {
      return null
    }
  }

  // 내가 작성한 수강 후기가 있다면 데이터 업데이트
  const getMyreview = (
    setTextareaValue,
    setTextareaLength,
    setLearningReviewSeq,
    handleGetPoint,
    handleUpdateImage
  ) => {
    api
      .get('/v2/review/learning-review/' + reviewData.studyGroupUserSeq)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setTextareaValue(response.data.data.contents)
          setTextareaLength(response.data.data.contents.length)
          setLearningReviewSeq(response.data.data.learningReviewSeq)
          handleGetPoint(response.data.data.satisfaction)
          handleUpdateImage(response.data.data.attachments)
        }
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  // 수강 후기 수정
  const editMyReview = (learningReviewSeq, textareaValue, satisfaction, registerImage) => {
    const data = {
      learningReviewSeq: learningReviewSeq,
      contents: textareaValue,
      satisfaction: satisfaction,
      attachments: registerImage
    }

    api
      .put('/v2/review', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)

          return false
        }

        alert('수강 후기 수정이 완료되었습니다.')
        window.location.reload()
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  // 상품 후기 수정
  const editProductReview = (
    learningReviewSeq,
    productTextareaValue,
    satisfaction,
    uploadImgData
  ) => {
    const data = {
      learningReviewSeq: learningReviewSeq,
      contents: productTextareaValue,
      satisfaction: satisfaction,
      attachments: uploadImgData
    }

    api
      .put('/v2/review', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)

          return false
        }

        alert('상품 후기 수정이 완료되었습니다.')
        window.location.reload()
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  // 상품 후기 조회
  const getProductReview = (
    setTextareaValue,
    setTextareaLength,
    setProductLearningReviewSeq,
    handleGetPoint
  ) => {
    api
      .get(`/v2/review/${learningReviewSeq}`)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setTextareaValue(response.data.data.contents)
          setTextareaLength(response.data.data.contents.length)
          setProductLearningReviewSeq(response.data.data.learningReviewSeq)
          handleGetPoint(response.data.data.satisfaction)
        }
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  const getReviewData = () => {
    api
      .get(`/v2/review/${learningReviewSeq}`)
      .then((response) => {
        setReviewData(response.data.data)
        setIsLoading(true)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  const deleteReviewData = () => {
    api
      .delete(`/v2/review/${learningReviewSeq}`)
      .then((response) => {
        alert('삭제가 완료되었습니다.')
        navigate('/mypage/qna/review')
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  useEffect(() => {
    getReviewData()

    // 페이지 벗어날때 모달 클래스 삭제
    return () => {
      document.body.classList.remove('modal-open')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="qna-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <MobileHeader
              title="후기"
              onClick={() =>
                document.referrer === '' ? navigate('/mypage/qna/review') : navigate(-1)
              }
            />
            <Lnb />
            <div className={styles.listDetailView}>
              <div className={styles.titleCol}>
                <strong>{reviewData.reviewTypeName}</strong>
              </div>
              <div className={`${styles.detailTitleArea} ${styles.board}`}>
                <div className={styles.thumbArea}>
                  <span
                    style={
                      reviewData.thumbnailPath && {
                        backgroundImage: `url(${reviewData.thumbnailPath})`
                      }
                    }
                  />
                </div>
                <div className={styles.infoArea}>
                  <div className={styles.class}>
                    <p className={`${styles.mainTit} ellipsis`}>{reviewData.title}</p>

                    <strong className={`${styles.starPoint} star-point`}>
                      <span
                        style={{
                          width: reviewData.satisfaction * 20 + '%'
                        }}
                      />
                    </strong>
                  </div>
                  <div className={styles.replyModify}>
                    <div className={styles.reply}>
                      <strong
                        className={
                          styles.check +
                          (reviewData.answerYn === 'Y' && reviewData.answer
                            ? ' ' + styles.completed
                            : '')
                        }
                      >
                        {reviewData.answerYn === 'Y' && reviewData.answer ? '답변완료' : '답변대기'}
                      </strong>
                      {reviewData?.registDate && (
                        <span className={styles.date}>
                          {formatDate(reviewData.registDate).replaceAll('-', '.')}
                        </span>
                      )}
                    </div>
                    <div className={styles.modify}>
                      {reviewData.answerYn === 'N' && (
                        <div className={`${styles.btnGroup} btn-group`}>
                          <button
                            className={`${styles.btnModify} btn-modify`}
                            onClick={handleOpenModalReview}
                          >
                            <span>수정</span>
                          </button>
                          <button
                            className={`${styles.btnDelete} btn-delete`}
                            onClick={handleOpenModalAlert}
                          >
                            <span>삭제</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.detailContent}>
                <div className={styles.detailQuestion}>
                  <DetailReviewForm
                    getReviewData={getReviewData}
                    reviewData={reviewData}
                    isLoading={isLoading}
                    isMyPage={true}
                  ></DetailReviewForm>
                </div>
              </div>
              <div className={`${styles.listBtnCol} list-btn-col`}>
                <div className="inner-col">
                  <Link
                    to={{
                      pathname: '/mypage/qna/review'
                    }}
                    className="btn btn-go-list"
                  >
                    목록보기
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalopenAlert ? (
        <ModalPortal>
          <AlertModal
            btnGroup
            msg={'작성하신 후기를 삭제하시겠습니까? 삭제시 복구할 수 없습니다.'}
            handleCloseModal={handleCloseModalAlert}
            handleConfirmClose={deleteReviewData}
          />
        </ModalPortal>
      ) : null}
      {modalopenReview ? (
        <ModalPortal>
          <ModalReviewWrite
            title={reviewData.reviewType === 'PRODUCT' ? '상품 후기 작성' : '수강 후기 작성'}
            btnClose
            studySeq={reviewData.studyGroupUserSeq}
            handleCloseModalReviewWrite={handleCloseModalReview}
            getMyreview={getMyreview}
            editMyReview={editMyReview}
            getProductReview={getProductReview}
            editProductReview={editProductReview}
            myClassLearningReviewSeq={learningReviewSeq}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
