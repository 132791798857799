import React, { useState, useEffect } from 'react'

import usePostImagesUpload from 'hooks/usePostImagesUpload'

import api from 'common/api'

import 'assets/styles/modal.scss'
import 'assets/styles/coupon.scss'
import 'assets/styles/_modalReviewWrite.scss'

import ImagesUpload from 'components/imagesUpload'

export default function ModalReviewWrite({
  title,
  btnClose,
  handleCloseModalReviewWrite,
  studySeq,
  getMyreview, // 내가 작성한 수강 후기가 있다면 데이터 업데이트
  postMyreview, // 수강 후기 등록
  editMyReview, // 수강 후기 수정
  postProductReview, // 상품 후기 등록
  inputValue, // 상품 후기 데이터
  getProductReview, // 내가 작성한 상품 후기가 있다면 데이터 업데이트
  editProductReview, // 상품 후기 수정
  myClassLearningReviewSeq
}) {
  const { postImagesUpload } = usePostImagesUpload()
  const [isDisabledButton, setIsDisabledButton] = useState(true)
  const [classData, setClassData] = useState(null)
  const [textareaLength, setTextareaLength] = useState(0)
  const [learningReviewSeq, setLearningReviewSeq] = useState(null)
  const [textareaValue, setTextareaValue] = useState('')
  const [satisfaction, setSatisfaction] = useState(null)
  const [fileImages, setFileImages] = useState([])
  // 상품 후기
  const [productLearningReviewSeq, setProductLearningReviewSeq] = useState(null)

  const handleGetPoint = (point) => {
    setSatisfaction(point)
  }

  const handleTextLengthCheck = (e) => {
    let textValue = e.target.value
    let textLength = e.target.value.length

    setTextareaLength(textLength)
    setTextareaValue(textValue)
  }

  // 수강후기 작성할 클래스 데이터
  const getClassData = () => {
    api
      .get('/v2/review/class/' + studySeq)
      .then((response) => {
        setClassData(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 마이클래스에서 상품후기 데이터 조회
  const getClassProductData = () => {
    api
      .get('/v2/review/' + myClassLearningReviewSeq)
      .then((response) => {
        setClassData(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getAttachment = async () => {
    try {
      if (fileImages.length === 0) {
        return null
      }
      let attachments = fileImages.filter((item) => item.attachmentName)

      if (fileImages.length > 0 || fileImages.filter((item) => !item.attachmentName) > 0) {
        const attachedImages = await postImagesUpload(
          fileImages.filter((item) => !item.attachmentName)
        )
        attachedImages?.map((list) =>
          attachments.push({
            attachmentName: list.saveFileName,
            attachmentPath: list.filePath,
            thumbnailPath: list.thumbnailFile.filePath
          })
        )
      }
      return attachments
    } catch (error) {
      console.error('Error during review submission:', error)
      alert('첨부파일 저장 중 오류가 발생했습니다.')
    } finally {
      setIsDisabledButton(false)
    }
  }

  const handleRegistMyReview = async () => {
    if (!satisfaction) {
      alert('만족도를 선택해주세요.')
      return false
    }

    if (textareaLength < 10) {
      alert('최소 10자 이상 입력해주세요.')
      return false
    }

    if (textareaLength >= 1000) {
      alert('최대 1000자까지 입력이 가능합니다.')
      return false
    }

    try {
      setIsDisabledButton(true)

      const attachments = await getAttachment()

      // modalProductSelect에서 가져온 inputValue가 있을 때 (상품 후기)
      if (inputValue) {
        if (productLearningReviewSeq) {
          editProductReview(productLearningReviewSeq, textareaValue, satisfaction)
        } else {
          postProductReview(
            inputValue.packageSeq,
            inputValue.productSeq,
            textareaValue,
            satisfaction
          )
        }
      }

      // 나의 문의 후기에서 상품 후기 수정
      if (myClassLearningReviewSeq && classData.reviewType === 'PRODUCT') {
        editProductReview(myClassLearningReviewSeq, textareaValue, satisfaction)
      }

      // 수강 후기 작성한 적이 있다면 수정 API, 없다면 등록 API
      if (learningReviewSeq) {
        editMyReview(learningReviewSeq, textareaValue, satisfaction, attachments)
      } else if (classData) {
        postMyreview(classData, textareaValue, satisfaction, attachments)
      }
    } catch (error) {
      console.error('Error during review submission:', error)
      alert('리뷰 등록 중 오류가 발생했습니다.')
    } finally {
      setIsDisabledButton(false)
    }
  }

  useEffect(() => {
    textareaLength <= 0 ? setIsDisabledButton(false) : setIsDisabledButton(true)
  }, [textareaValue, textareaLength])

  useEffect(() => {
    if (studySeq) {
      getClassData()
    }
    if (myClassLearningReviewSeq) {
      getClassProductData()
    }
  }, [])

  useEffect(() => {
    if (getMyreview && title === '수강 후기 작성') {
      getMyreview(
        setTextareaValue,
        setTextareaLength,
        setLearningReviewSeq,
        handleGetPoint,
        setFileImages
      )
    }
  }, [getMyreview])

  useEffect(() => {
    if (getProductReview) {
      getProductReview(
        setTextareaValue,
        setTextareaLength,
        setProductLearningReviewSeq,
        handleGetPoint
      )
    }
  }, [getProductReview])

  useEffect(() => {
    if (satisfaction && textareaLength > 10) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [satisfaction, textareaLength])

  return (
    <>
      <div className="modal-wrap class-review">
        <div className="dimmed" onClick={handleCloseModalReviewWrite}></div>
        <div className="modal-inner">
          {btnClose && (
            <button
              type="button"
              className="btn-modal-close new"
              onClick={handleCloseModalReviewWrite}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4 4L20 20M20 4L4 20"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}
          <div className="modal-header">
            <strong>{title}</strong>
          </div>
          <div className="modal-content">
            {classData && (
              <div className="class-info-box">
                <span
                  className="class-img"
                  style={{
                    backgroundImage: 'url(' + classData.thumbnailPath + ')'
                  }}
                ></span>
                <p>
                  <span className="class-name">{classData.className || classData.title}</span>
                  <span className="class-description">
                    {classData.teacherName ? `${classData.teacherName}, ` : ''}
                    {classData.contentType ? `${classData.contentType}강의, ` : ''}
                    {classData.lectureCount ? `총 ${classData.lectureCount || 0}강` : ''}
                  </span>
                </p>
              </div>
            )}
            {inputValue ? (
              <div className="class-info-box">
                <span
                  className="class-img"
                  style={{
                    backgroundImage: 'url(' + inputValue.thumbnailPath + ')'
                  }}
                ></span>
                <p>
                  <span className="class-name">{inputValue.productName}</span>
                </p>
              </div>
            ) : null}
            <div className="review-box">
              <form name="reviewWriteFrm" action="#">
                <dl>
                  <dt>만족도는 어떠셨나요?</dt>
                  <dd>
                    <div className="point-star-box">
                      <span
                        id="starPoint"
                        style={{
                          width: `${satisfaction * 20 + '%'}`
                        }}
                      ></span>
                      <div className="btn-group">
                        {Array.from({ length: 5 }, (_, i) => (
                          <button
                            key={i}
                            type="button"
                            onClick={() => {
                              handleGetPoint(i + 1)
                            }}
                          >
                            {i + 1}
                          </button>
                        ))}
                      </div>
                    </div>
                    별점을 선택해주세요.
                  </dd>
                </dl>
                <span className="text-box-label">수강 후기 작성</span>
                <div className="text-box">
                  <textarea
                    placeholder="어떤 점이 만족스러웠나요?&#13;&#10;나만의 공부 노하우는 무엇인가요?&#13;&#10;최소 10자 이상 입력해주세요!"
                    id="reviewText"
                    maxLength="1000"
                    value={textareaValue}
                    onChange={handleTextLengthCheck}
                  />
                  <strong>
                    <span id="textLength">{textareaLength}</span>
                    /1000
                  </strong>
                </div>
                <ImagesUpload fileImages={fileImages} setFileImages={setFileImages} />
              </form>
            </div>
            <div className="notice-box">
              <strong>※ 수강 후기 작성 안내</strong>
              <ul>
                <li>
                  약관 및 법률에 위배(비속어, 허위사실 등), 해석이 불가능하거나 상품 후기 외 다른
                  목적의 글을 작성한 경우 관리자에 의해 사전 동의 없이 미게시 될 수 있습니다.
                </li>
                <li>작성하신 후기는 홍보, 이벤트 등 다양한 방법으로 활용될 수 있습니다.</li>
              </ul>
              <div className="btn-flex-form">
                <button
                  className={'button disabled' + (!isDisabledButton ? ' active' : '')}
                  disabled={isDisabledButton}
                  onClick={handleRegistMyReview}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
