import { useState, useCallback } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

import classNames from 'classnames'

import { headerValues } from 'components/header/config'

import GnbSelectModal from './GnbSelectModal'
import styles from '../../styles/SubHeader.module.scss'

export default function SubLogo({ gnbItems, currentIdx }) {
  const [isShowSelectModal, setIsShowSelectModal] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 750 })
  const brandName = headerValues.subLogoImage[currentIdx]

  const handleOpenModal = useCallback(() => {
    setIsShowSelectModal(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsShowSelectModal(false)
  }, [])

  return (
    <div className={styles.subLogo}>
      <Link
        to={gnbItems && gnbItems.length > 0 ? gnbItems[currentIdx]?.link : '#'}
        className={classNames(styles[brandName], styles.logo)}
      >
        <img src={`${headerValues.HOST_CDN}icon-header-logo-${brandName}.svg`} alt="logo" />
      </Link>
      {isMobile && (
        <button className={styles.dropdownButton} onClick={handleOpenModal}>
          <img src={`${headerValues.HOST_CDN}icon-header-dropdown.png`} alt="Open dropdown menu" />
        </button>
      )}

      {isShowSelectModal && <GnbSelectModal onClose={handleCloseModal} />}
    </div>
  )
}
