const alertMessage = {
  FORM_USERNAME_REQUIRED: '이름을 입력해 주세요.',
  FORM_USERNAME_VALIDATION: '이름은 한글만 입력가능 합니다.',
  FORM_PHONE_REQUIRED: '전화번호를 입력해 주세요.',
  FORM_COUNSEL_REQUIRED: '상담 가능 시간을 선택해 주세요',
  FORM_GOAL_REQUIRED: '학습목표를 선택해 주세요',
  FORM_AGE_REQUIRED: '연령대를 선택해 주세요',
  FORM_DEVICE_OPTION_REQUIRED: '원하는 기기를 선택해 주세요',
  FORM_PHONE_VALIDATION: '올바른 전화번호를 입력해 주세요.',
  FORM_PRIVACY_REQUIRED: '개인정보 수집에 동의해 주세요.',
  FORM_PACKAGE_REQUIRED: '구매 패키지를 선택해 주세요.',
  CONSULTATION_COMPLETE: '빠른 상담 신청이 완료되었습니다.',
  FORM_CHALLENGE_REQUIRED: '2025년 나의 챌린지를 입력해 주세요.',
  CONSULTATION_DUPLICATED:
    '이미 상담 신청하셨어요! \n해당 번호로는 더 이상 접수 불가합니다.\n(* 추가적인 상품 문의 사항은 상담원과의 통화 시 말씀해 주세요.)'
}

export default alertMessage
