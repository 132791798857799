import { YANADOO_CDN_HOST } from 'common/config'

const headerValues = {
  HOST_CDN: `${YANADOO_CDN_HOST}/common/header/`,

  gnbMenuSeq: {
    49: 'eng',
    105: 'yu',
    71: 'yafit'
  },
  subLogo: ['logo-eng', 'logo-yafit', 'logo-yu'],
  subLogoImage: ['english', 'yafit', 'class'],
  headerHidePaths: [
    '/promotion-landing/standByMe',
    '/promotion-landing/applePackageUI',
    '/promotion-landing/SRRfullpackage_performance',
    '/promotion-landing/standByMe',
    '/promotion-landing/aiSrrSecret',
    '/promotion-landing/yndGuide',
    '/promotion-landing/macbookPackage_freepass',
    '/promotion-landing/class_secret',
    '/promotion-landing/macbookPackage_together',
    '/promotion-landing/macbookPackage_freepassB'
  ],
  urlCustomActiveList: [
    { pagePath: '/levelTest/exam', matchUrl: '/levelTest/start' },
    { pagePath: '/levelTest/result', matchUrl: '/levelTest/start' },
    { pagePath: '/levelTest2/exam', matchUrl: '/levelTest2/start' },
    { pagePath: '/levelTest2/result', matchUrl: '/levelTest2/start' },
    { pagePath: '/search-result', matchUrl: '/store/list' },
    { pagePath: '/community/srr/review', matchUrl: '/community/srr/list' },
    { pagePath: '/mall/lllcard', matchUrl: '/EnglishHome' }
  ],
  subLinkList: [
    {
      name: '야나두',
      description: `하루 10분, 영어를 마스터하는 신기한 방법`,
      imgPath: `${YANADOO_CDN_HOST}/common/header/icon-symbol-english.svg`,
      pathname: '/'
    },
    {
      name: '야핏',
      description: `운동한만큼 돌려받는 메타버스 홈트레이닝`,
      imgPath: `${YANADOO_CDN_HOST}/common/header/icon-symbol-cycle.svg`,
      pathname: `${process.env.REACT_APP_YANAFIT_DOMAIN}yanadoo/promotion/yafitAllInOne.html`
    },
    {
      name: '야핏 무브',
      description: (
        <>
          움직이면 돈이 되는 <br /> 운동 습관 앱
        </>
      ),
      imgPath: `${YANADOO_CDN_HOST}/common/header/icon-symbol-move.svg`,
      pathname: `${process.env.REACT_APP_YANAFIT_DOMAIN}yanadoo/web/yafitMove.html`
    },
    {
      name: '야나두 클래스',
      description: (
        <>
          나의 성공 잠재력을 <br /> 깨워줄 특별한 클래스
        </>
      ),
      imgPath: `${YANADOO_CDN_HOST}/common/header/icon-symbol-class.svg`,
      pathname: '/store/list'
    }
  ],
  searchRecommendedList: [
    { id: '0', text: '일본어' },
    { id: '1', text: '중국어' },
    { id: '2', text: '제2외국어' },
    { id: '3', text: '영어' },
    { id: '4', text: '주니어영어' },
    { id: '5', text: '재테크' },
    { id: '6', text: '부업' },
    { id: '7', text: '실무' },
    { id: '8', text: '이모티콘' },
    { id: '9', text: '마케팅' },
    { id: '10', text: '유튜버' }
  ]
}

export { headerValues }
