import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import TagManager from 'react-gtm-module'
import { useLocation } from 'react-router-dom'

import ChannelService from 'ChannelService'

import { hideChannelTalkPaths, CHANNEL_TALK_KEY, CHANNEL_TALK_IMAGE_URL } from './config'

export default function ChannelTalk() {
  const location = useLocation()

  const checkShouldHide = hideChannelTalkPaths.some((path) => location.pathname === path)

  const elementVisibility = (selector, isVisible) => {
    const element = document.querySelector(selector)
    if (element) {
      element.style.setProperty('display', isVisible ? 'none' : 'block', 'important')
    }
  }

  const handleClickChannelTalk = () => {
    // window.dataLayer.push({
    //   event: 'channel-mo-bottom-CTA',
    //   product_name: '영어_채널톡_상담하기_mo',
    //   button_id: 'ch-custom-button'
    // })
    TagManager.dataLayer({
      dataLayer: {
        event: 'channel-mo-bottom-CTA',
        product_name: '영어_채널톡_상담하기_mo',
        button_id: 'ch-custom-button'
      }
    })
  }

  useEffect(() => {
    elementVisibility('#ch-plugin', checkShouldHide)
    elementVisibility('#ch-custom-button', checkShouldHide)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    const ChannelIO = new ChannelService()
    const pluginKey = CHANNEL_TALK_KEY

    const options = isMobile
      ? {
          pluginKey,
          customLauncherSelector: '#ch-custom-button',
          hideChannelButtonOnBoot: true
        }
      : {
          pluginKey
        }

    ChannelIO.boot(options, () => {
      elementVisibility('#ch-plugin', checkShouldHide)

      if (!isMobile && window.ChannelIO) {
        // Shadow DOM 안에 있는 채널톡 기본 버튼 GTM 트래킹
        window.ChannelIO('onShowMessenger', function () {
          // window.dataLayer.push({
          //   event: 'channel-pc-bottom-CTA',
          //   product_name: '영어_채널톡_상담하기_pc',
          //   button_id: 'ch-plugin-launcher'
          // })

          TagManager.dataLayer({
            dataLayer: {
              event: 'channel-pc-bottom-CTA',
              product_name: '영어_채널톡_상담하기_pc',
              button_id: 'ch-plugin-launcher'
            }
          })
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isMobile && (
        <button
          id="ch-custom-button"
          style={{
            position: 'relative',
            width: '55px',
            height: '55px',
            marginTop: '9px',
            borderRadius: '50%',
            backgroundImage: `url(${CHANNEL_TALK_IMAGE_URL})`,
            backgroundSize: 'cover'
          }}
          onClick={handleClickChannelTalk}
        >
          <p
            style={{
              position: 'absolute',
              top: '-9999px',
              left: '-9999px'
            }}
          >
            야나두 상담 버튼
          </p>
          <div
            style={{
              position: 'absolute',
              top: '2px',
              right: '4px',
              width: '8px',
              height: '8px',
              backgroundColor: 'rgb(233, 78, 88)',
              borderRadius: '50%',
              transform: 'translate(50%, -50%)'
            }}
          ></div>
        </button>
      )}
    </>
  )
}
