import Modal from './Modal'
import s from '../aiTalk.module.css'

export default function ModalEnd({ close, confirm }) {
  return (
    <Modal onClose={close}>
      <div className={s.modalAlert}>
        <div className={s.content}>
          <p>
            대화를 종료하시겠습니까?
            <i>대화 종료 시 내용은 저장되지 않습니다.</i>
          </p>
        </div>
        <div className={s.btnGroup}>
          <button onClick={confirm}>계속 대화하기</button>
          <button onClick={close}>종료</button>
        </div>
      </div>
    </Modal>
  )
}
