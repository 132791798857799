export const redirectPromotionLandingPath = {
  '/promotion-landing/applePackage.html': '/promotion-landing/applePackage',
  '/promotion/bestAwards_db_only': '/promotion-landing/bestAwardsDBOnly',
  '/promotion-landing/bestAwards_db_only': '/promotion-landing/bestAwardsDBOnly',
  '/promotion-landing/bestAwards_db_only.html': '/promotion-landing/bestAwardsDBOnly',
  '/promotion-landing/bestAwards.html': '/promotion-landing/bestAwards',
  '/promotion-landing/b2bLanding.html': '/promotion-landing/b2bLanding',
  '/promotion-landing/brain.html': '/promotion-landing/brain',
  '/promotion-landing/curriculum.html': '/promotion-landing/curriculum',
  '/promotion-landing/laptopPackage.html': '/promotion-landing/laptopPackage',
  '/promotion-landing/macbookPackage.html': '/promotion-landing/macbookPackage',
  '/promotion-landing/scholarShip.html': '/promotion-landing/scholarShip',
  '/promotion-landing/SRRfullpackage.html': '/promotion-landing/SRRfullpackage',
  '/promotion-landing/SRRworkbookbrand.html': '/promotion-landing/SRRworkbookbrand',
  '/promotion-landing/tabPackage_air.html': '/promotion-landing/tabPackageAir',
  '/promotion-landing/tabPackage_air5th.html': '/promotion-landing/tabPackageAir',
  '/promotion-landing/tabPackage10th.html': '/promotion-landing/tabPackage10th',
  '/promotion-landing/yndGuide.html': '/promotion-landing/yndGuide',
  '/promotion-landing/company.html': '/company',
  '/promotion-landing/yanadooInfo.html': '/yanadooInfo',
  '/promotion-landing/SRRworkbook.html': '/promotion-landing/SRRworkbook',
  // promotion to class
  '/promotion-landing/audioEnglish': '/store/detail/1008422',
  '/promotion-landing/audioEnglish.html': '/store/detail/1008422',
  '/promotion-landing/realEnglish': '/store/detail/1008425',
  '/promotion-landing/realEnglish.html': '/store/detail/1008425',
  '/promotion-landing/n_nipowoopo2024': '/store/detail/1008433',
  '/promotion-landing/n_nipowoopo2023.html': '/store/detail/1008433',
  '/promotion-landing/n_nipowoopo2024.html': '/store/detail/1008433',
  '/promotion-landing/tripEnglish': '/store/detail/1008434',
  '/promotion-landing/tripEnglish.html': '/store/detail/1008434',
  '/promotion-landing/newChallenge': '/store/detail/1009063 ',
  '/promotion-landing/buddytalk': '/store/detail/1009066',
  '/promotion-landing/frontEnglish': '/store/detail/1009067',
  '/promotion-landing/frontEnglish.html': '/store/detail/1009067'
}
