import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { BrowserRouter } from 'react-router-dom'

import Clarity from '@microsoft/clarity'
import useFetchIpAddress from 'hooks/useFetchIpAddress'
import ModalPortal from 'ModalPortal'
import AppRouter from 'router/AppRouter'
import ScrollToTop from 'ScrollToTop'

import { YANADOO_CLARITY_ID, YANADOO_GTM_ID } from 'common/config'
import ModalLogin from 'components/modal/ModalLogin'
import useModalLogin from 'store/useModalLogin'

import 'assets/styles/common.scss'

export default function App() {
  const { isModalLogin, setIsModalLogin } = useModalLogin()
  const { isBlocked, excludedIp } = useFetchIpAddress() // 폐쇄망 ip 확인 hooks
  const isProduction = process.env.NODE_ENV === 'production' // 운영서버 확인

  const handleCloseLogin = () => {
    document.body.classList.remove('modal-open')
    setIsModalLogin(false)
  }

  useEffect(() => {
    // gtm dataLayer 미리 초기화 (GTM 미사용 시도 대비)
    if (!window.dataLayer) {
      window.dataLayer = []
    }

    // 운영서버의 폐쇄망이 아닐 때, gtm / clariry init
    if (isProduction && !isBlocked && excludedIp === 'N') {
      TagManager.initialize({ gtmId: YANADOO_GTM_ID }) // gtm init
      Clarity.init(YANADOO_CLARITY_ID) // clarity init
    }
  }, [isBlocked, excludedIp, isProduction])

  return (
    <BrowserRouter>
      <ScrollToTop />
      <AppRouter />
      {isModalLogin && (
        <ModalPortal>
          <ModalLogin title={'로그인'} btnClose handleCloseModal={handleCloseLogin} />
        </ModalPortal>
      )}
    </BrowserRouter>
  )
}
