export const hideChannelTalkPaths = [
  'mypage',
  'myclass',
  'mall',
  'levelTestAdvanced/exam',
  'levelTestAdvanced/result',
  'levelTestAdvanced/answer',
  'AInativespeakertalk',
  'AInativespeakertalk/chat',
  'community/srr/review',
  'courseReview',
  '/promotion-landing/applePackageUI',
  '/promotion-landing/SRRfullpackage_performance',
  '/webview/ai/intro',
  '/webview/ai/chat',
  '/webview/ai/qna',
  '/webview/ai/srr/qna',
  '/webview/login',
  '/webview/loginSuccess',
  '/webview/travel',
  '/webview/travel/chat',
  '/service/faq/ai',
  '/levelTestAi/start',
  '/levelTestAi/exam',
  '/levelTestAi/result',
  '/levelTestAi/answer'
]

export const CHANNEL_TALK_KEY = '627cbef3-bd97-440b-a8a5-8412e492630b'

export const CHANNEL_TALK_IMAGE_URL =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_channeltalk_250305.png'
