import React, { useRef, useState } from 'react'

import cx from 'classnames'

import s from './InputPhone.module.scss'

export default function InputPhone({ inputValue, handleChange, isBottom, isModal }) {
  const phoneInputRef = useRef(null)
  const [placeholder, setPlaceholder] = useState(true)

  const handleInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 11)
  }

  const handleFocus = (e) => {
    setPlaceholder(false)
  }
  const handleBlur = (e) => {
    if (e.target.value === '010' || e.target.value === '') {
      setPlaceholder(true)
    }
  }

  return (
    <div className={cx(s.inputPhoneWrap, { [s.bottomFixed]: isBottom, [s.modal]: isModal })}>
      <input
        ref={phoneInputRef}
        type="tel"
        id="phone"
        value={inputValue}
        onChange={handleChange}
        onInput={handleInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {placeholder && (
        <span className={s.placeholder} onClick={() => phoneInputRef.current.focus()}>
          - 없이 작성해주세요
        </span>
      )}
    </div>
  )
}
