import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import useFloatingElementStore from 'store/useFloatingElementStore'

const FloatingBannerHeight = ({ children }) => {
  const location = useLocation()
  const { setFloatingElementHeight } = useFloatingElementStore()
  const elementRef = useRef(null)

  useEffect(() => {
    const updateHeight = () => {
      const element = elementRef.current

      if (element) {
        const height = element.getBoundingClientRect().height
        const talkBox = element.querySelector('#talkBox')
        const talkBoxHeight = talkBox?.offsetHeight || 0

        const safeAreaInsetBottom = parseInt(getComputedStyle(element).bottom || '0px') || 0

        if (!isNaN(height)) {
          const adjustedHeight = height + talkBoxHeight / 2 + safeAreaInsetBottom
          setFloatingElementHeight(adjustedHeight)
        }
      } else {
        setFloatingElementHeight(0)
      }
    }

    updateHeight()
    const timeoutId = setTimeout(updateHeight, 100)

    window.addEventListener('resize', updateHeight)

    return () => {
      window.removeEventListener('resize', updateHeight)
      clearTimeout(timeoutId)
      setFloatingElementHeight(0)
    }
  }, [location.pathname, setFloatingElementHeight])

  // children이 null이거나 false인 경우 처리
  if (!children) {
    return null
  }

  // 실제로 렌더링되는 자식 요소 찾기
  const child = React.Children.toArray(children).find((child) => child)

  if (!child) {
    return null
  }

  return React.cloneElement(child, {
    ref: elementRef
  })
}

export default FloatingBannerHeight
