import { useMemo } from 'react'
import { Navigate, useLocation } from 'react-router'

import { redirectPromotionLandingPath } from './config'

/**
 * 라우팅되지 않은 페이지를 redirect하는 컴포넌트
 * (html링크로 진입시 전환된 리액트 페이지로 이동)
 * @returns {JSX.Element}
 */

export default function Redirect() {
  const { pathname, search } = useLocation()
  const isPromotionLanding = pathname.includes('/promotion-landing/')
  const isPromotion = pathname.includes('/promotion/')
  /**
   * pathname에 맞는 redirect path를 찾아서 반환하는 함수
   */
  const redirectPath = useMemo(() => {
    // promotion-landing 페이지로 진입시 config에 정의된 path로 이동
    if (isPromotionLanding && redirectPromotionLandingPath.hasOwnProperty(pathname)) {
      return redirectPromotionLandingPath[pathname]
    }
    // promotion 페이지로 진입시 promotion-landing으로 이동
    if (isPromotion) {
      return pathname.replace('/promotion/', '/promotion-landing/')
    }
    return null
  }, [isPromotion, isPromotionLanding, pathname])

  /**
   *  redirectPath가 아직 결정되지 않은 경우 렌더링 방지
   */
  if (!redirectPath) {
    return <Navigate to="/" replace />
  }

  // 내부 링크로 이동
  return <Navigate to={`${redirectPath}${search}`} replace />
}
