import { YANADOO_CDN_HOST } from 'common/config'

export const keyVisualBannerValues = {
  banners: {
    1: {
      images: [
        {
          pc: `${YANADOO_CDN_HOST}/promotion/etc/img_kv_realtalk_250205.png`,
          mobile: `${YANADOO_CDN_HOST}/promotion/etc/img_m_kv_realtalk_250205.png`,
          background: `#005DF9`,
          startDate: '2025-02-01T00:00',
          endDate: '2025-03-05T00:00'
        },
        {
          pc: `${YANADOO_CDN_HOST}/promotion/etc/img_kv_aifull_250205.png`,
          mobile: `${YANADOO_CDN_HOST}/promotion/etc/img_m_kv_aifull_250205.png`,
          background: `#005DF9`,
          startDate: '2025-02-01T00:00',
          endDate: '2025-03-05T00:00'
        }
      ]
    },
    2: {
      images: [
        {
          pc: `${YANADOO_CDN_HOST}/promotion/etc/img_kv_aifull_250205.png`,
          mobile: `${YANADOO_CDN_HOST}/promotion/etc/img_m_kv_aifull_250205.png`,
          background: `#0167FF`,
          startDate: '2025-02-01T00:00',
          endDate: '2025-03-05T00:00'
        }
      ]
    },
    3: {
      images: [
        {
          pc: `${YANADOO_CDN_HOST}/promotion/etc/img_kv_realtalk_p_250205.png`,
          mobile: `${YANADOO_CDN_HOST}/promotion/etc/img_m_kv_realtalk_p_250205.png`,
          background: `#7C2AFF`,
          startDate: '2025-02-05T00:00',
          endDate: '2025-03-05T00:00'
        }
      ]
    }
  },
  paths: {
    1: ['/promotion-landing/bestAwardsDBOnly', '/promotion-landing/macbookPackage'],
    2: [
      '/promotion-landing/ipadDouble',
      '/promotion-landing/applePackage',
      '/promotion-landing/tabPackageAir'
    ],
    3: ['/promotion-landing/laptopPackage']
  }
}
