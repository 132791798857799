// 준비사항 - part 설명
export const prepareContentsValues = [
  {
    id: 1,
    title: 'Part 1. 어휘/문법',
    description: `알맞은 단어 사용과 문법에 대한\n 이해력을 평가합니다`,
    count: 9
  },
  {
    id: 2,
    title: 'Part 2. 뉘앙스',
    description: `상황에 맞는 단어와 표현으로 대화의\n 뉘앙스를 파악할 수 있는지 확인합니다`,
    count: 4
  },
  {
    id: 3,
    title: 'Part 3. 듣기/말하기',
    description: `주제와 상황에 맞게 이해하고\n 정확하게 답변하는 능력을 확인합니다`,
    count: 7
  }
]
export const levelTestAdvancedValues = {
  marketingCookitName: 'yndLevelTestAdvancedMarketing'
}
