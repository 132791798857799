import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import ResponsiveImage from 'components/ResponsiveImage'

import { keyVisualBannerValues } from './config'
import { getCurrentKSTDateTime } from './util'

export default function KeyVisualBanner() {
  const location = useLocation()
  const [currentTime, setCurrentTime] = useState('')

  useEffect(() => {
    setCurrentTime(getCurrentKSTDateTime()) // 최초 렌더링 시 한 번만 현재 시간 설정
  }, [])

  // 현재 경로에 해당하는 배너 키 찾기
  const bannerKey = Object.keys(keyVisualBannerValues.paths).find((key) =>
    keyVisualBannerValues.paths[key]
      .map((path) => path.toLowerCase())
      .includes(location.pathname.toLowerCase())
  )

  // 해당 키값으로 배너 데이터 가져오기
  const banner = bannerKey ? keyVisualBannerValues.banners[bannerKey] : null

  if (!banner || !banner.images || banner.images.length === 0) {
    return null // 배너가 없으면 렌더링 안 함
  }

  // 현재 시간에 맞는 이미지 찾기
  const activeImage = banner.images.find(
    (image) =>
      currentTime >= image.startDate && // 시작 시간이 현재 시간보다 이전이어야 함
      (!image.endDate || currentTime <= image.endDate) // 종료 시간이 없으면 계속 노출
  )

  // 현재 노출할 이미지가 없으면 렌더링 안 함
  if (!activeImage) return null

  return (
    <div style={{ textAlign: 'center', background: activeImage.background || '#fff' }}>
      <ResponsiveImage
        style={{ maxWidth: '1920px', display: 'inline-block' }}
        maxWidth={750}
        pc={activeImage.pc}
        mo={activeImage.mobile}
      />
    </div>
  )
}
