import { Link } from 'react-router-dom'

import ModalPortal from 'ModalPortal'

import DynamicLink from 'components/DynamicLink'
import { headerValues } from 'components/header/config'

import styles from '../../styles/GnbSelectModal.module.scss'

export default function GnbSelectModal({ onClose }) {
  return (
    <ModalPortal onClose={onClose}>
      <div className={styles.modalWrap} onClick={onClose}></div>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>
          <img src={`${headerValues.HOST_CDN}icon-gnb-select-modal-close.png`} alt="닫기" />
        </button>
        <p className={styles.title}>
          어떤 페이지로 <br />
          이동하고 싶으신가요?
        </p>

        <div className={styles.linkList}>
          {headerValues.subLinkList.map(({ name, pathname, description, imgPath }) => (
            <button key={name} className={styles.button} onClick={onClose}>
              <DynamicLink pathname={pathname}>
                <img src={imgPath} alt="logo" className={styles.symbol} />
                <p className={styles.name}>{name}</p>
                <p className={styles.description}>{description}</p>
              </DynamicLink>
            </button>
          ))}
        </div>
      </div>
    </ModalPortal>
  )
}
