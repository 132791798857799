import { useEffect, useState } from 'react'
import Lottie from 'react-lottie-player'
import { useLocation } from 'react-router'

import LikeAnimation from 'assets/lottie/like.json'
import ModalPortal from 'ModalPortal'

import api from 'common/api'

import styles from './DetailReviewButtons.module.scss'
import ModalShare from './modal/ModalShare'
import { formatDate } from './promotion/utile/utile'

export default function DetailReviewButtons({
  type,
  data,
  isList = false, // 리얼 수강 후기 목록
  isShowDate = true // 마이페이지 > 수강후기 상세: 날짜 노출 X
}) {
  const location = useLocation()
  const pathname = location.pathname

  const [liked, setLiked] = useState(data?.liked)
  const [likeActive, setLikeActive] = useState(false)
  const [likeCount, setLikeCount] = useState(data?.likeCount)

  const [shareItemData, setShareItemData] = useState(null)
  const [shareModalOpen, setShareModalOpen] = useState(false)

  const moveUrlLists = {
    // 스르르 학습지 후기
    REVIEW: {
      moveUrl: '/community/review/detail/'
    },

    // 리얼 수강 후기
    REAL: {
      moveUrl: '/courseReview/detail/'
    }
  }[type]

  const handleToggleLike = async () => {
    const seq = data?.scholarshipRefundHistorySeq
      ? data?.scholarshipRefundHistorySeq
      : data?.learningReviewSeq
      ? data?.learningReviewSeq
      : data?.seq

    const relationType =
      data?.type === 'LEARNING' || data?.reviewType === 'LEARNING' || type === 'REVIEW'
        ? 'REVIEW'
        : 'SCHOLARSHIP_REFUND_HISTORY'

    const response = await api
      .post(`v2/reaction/like?relationSeq=${seq}&relationType=${relationType}`)
      .then((response) => response.data)
      .catch((e) => {
        console.log(e)
      })

    if (response.meta.code === 200) {
      setLikeCount(response.data?.likeCount)
      setLiked(response.data?.isActive === 'Y')
      setLikeActive(response.data?.isActive === 'Y')
    }
  }

  const postShare = (seq, relationType) => {
    api
      .post(
        `v2/reaction/increase?reactionType=SHARE&relationSeq=${seq}&relationType=${relationType}`
      )
      .catch((e) => {
        console.log(e)
      })
  }

  const handleOpenShareModal = (e, data) => {
    document.body.classList.add('modal-open')
    setShareModalOpen(true)
    setShareItemData(data)
  }

  const handleCloseShareModal = (e) => {
    document.body.classList.remove('modal-open')
    setShareModalOpen(false)
  }

  useEffect(() => {
    setLikeCount(data?.likeCount)
    setLiked(data?.liked)
  }, [data])

  if (!data) return null

  return (
    <>
      <div className={`${styles.buttons} ${isList ? styles.listType : ''}`}>
        <button
          type="button"
          className={`${styles.like} ${liked ? styles.isLiked : ''}`}
          onClick={handleToggleLike}
        >
          <div className={styles.heart}>
            {likeActive ? (
              <Lottie
                animationData={LikeAnimation}
                play={true}
                loop={false}
                className={styles.likeAnimation}
              />
            ) : liked ? (
              <IconLikeActive />
            ) : (
              <IconLike />
            )}
          </div>
          <p>{likeCount > 999 ? '999+' : likeCount}</p>
        </button>

        {isList && (
          <div className={styles.comment}>
            <IconComment />
            <p>{data?.commentCount}</p>
          </div>
        )}

        <button
          type="button"
          className={styles.share}
          onClick={(e) => {
            handleOpenShareModal(e, data)
          }}
        >
          <IconShare />
        </button>

        {data?.registDate && isShowDate && !isList && (
          <p className={styles.date}>{formatDate(data?.registDate).replaceAll('-', '.')}</p>
        )}
      </div>

      {shareModalOpen && (
        <ModalPortal>
          <ModalShare
            title="수강생들의 리뷰를"
            snsShareTitle="학습지 후기"
            btnClose
            data={shareItemData}
            postShare={postShare}
            banner={data?.thumbnailPath}
            handleCloseModal={handleCloseShareModal}
            pathname={
              // 스르르 학습지 후기면
              type === 'REVIEW'
                ? moveUrlLists.moveUrl + data?.learningReviewSeq
                : !data?.type
                ? pathname
                : // 장학금 후기 시퀀스가 있다면
                data?.scholarshipRefundHistorySeq
                ? moveUrlLists.moveUrl + data?.seq + '/' + data?.scholarshipRefundHistorySeq // 장학금 후기
                : // 일반 리얼후기 시퀀스가 있다면
                data?.seq
                ? moveUrlLists.moveUrl + data?.seq + '/' + 0 // 일반 리얼수강후기
                : moveUrlLists.moveUrl + data?.learningReviewSeq // 스르르 학습지 후기
            }
          />
        </ModalPortal>
      )}
    </>
  )
}

function IconLike() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M17.9301 5.20828C15.7923 4.18819 12.9981 5.15054 12.0042 7.34468C11.0103 5.15054 8.21606 4.18819 6.07822 5.20828C3.79036 6.30535 2.70268 9.07691 4.50297 12.3489C5.68441 14.5045 7.72848 16.179 11.179 18.9121C11.6666 19.297 12.3417 19.297 12.8105 18.9121C16.2611 16.1598 18.3052 14.5045 19.4866 12.3296C21.3056 9.0769 20.218 6.30535 17.9301 5.20828Z"
        stroke="#A0A0B6"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function IconLikeActive() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M17.9301 5.20828C15.7923 4.18819 12.9981 5.15054 12.0042 7.34468C11.0103 5.15054 8.21606 4.18819 6.07822 5.20828C3.79036 6.30535 2.70268 9.07691 4.50297 12.3489C5.68441 14.5045 7.72848 16.179 11.179 18.9121C11.6666 19.297 12.3417 19.297 12.8105 18.9121C16.2611 16.1598 18.3052 14.5045 19.4866 12.3296C21.3056 9.0769 20.218 6.30535 17.9301 5.20828Z"
        fill="#FF5647"
        stroke="#FF5647"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function IconShare() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="17.5" cy="5.5" r="2.5" stroke="#A0A0B6" strokeWidth="2" />
      <circle cx="5.5" cy="11.5" r="2.5" stroke="#A0A0B6" strokeWidth="2" />
      <circle cx="17.5" cy="17.5" r="2.5" stroke="#A0A0B6" strokeWidth="2" />
      <path d="M15.1719 6.22266L8.07248 10.7154" stroke="#A0A0B6" strokeWidth="2" />
      <path d="M7.72357 12.9773L14.8331 17.1069" stroke="#A0A0B6" strokeWidth="2" />
    </svg>
  )
}

function IconComment() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C6.9476 4 3 7.4271 3 11.4919C3 13.3536 3.81723 15.0683 5.1924 16.3908C5.31882 16.5124 5.44996 16.6307 5.58561 16.7454C5.79433 16.922 5.87884 17.1949 5.80367 17.4497C5.56304 18.2653 5.02342 19.3043 4.28571 20C6.85714 20 7.63615 19.4851 8.61901 18.9837C9.19387 18.6905 9.5668 18.7252 10.1754 18.8296C10.764 18.9306 11.3742 18.9837 12 18.9837C17.0524 18.9837 21 15.5566 21 11.4919C21 7.4271 17.0524 4 12 4Z"
        stroke="#A0A0B6"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99943 11.5C8.99943 12.0523 8.55184 12.5 7.99971 12.5C7.44759 12.5 7 12.0523 7 11.5C7 10.9477 7.44759 10.5 7.99971 10.5C8.55184 10.5 8.99943 10.9477 8.99943 11.5Z"
        fill="#A0A0B6"
      />
      <path
        d="M13.0002 11.5C13.0002 12.0523 12.5526 12.5 12.0005 12.5C11.4484 12.5 11.0008 12.0523 11.0008 11.5C11.0008 10.9477 11.4484 10.5 12.0005 10.5C12.5526 10.5 13.0002 10.9477 13.0002 11.5Z"
        fill="#A0A0B6"
      />
      <path
        d="M17 11.5C17 12.0523 16.5524 12.5 16.0003 12.5C15.4482 12.5 15.0006 12.0523 15.0006 11.5C15.0006 10.9477 15.4482 10.5 16.0003 10.5C16.5524 10.5 17 10.9477 17 11.5Z"
        fill="#A0A0B6"
      />
    </svg>
  )
}
