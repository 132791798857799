import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

import ClassSearch from './ClassSearch'
import HeaderEventButton from './HeaderEventButton'
import HeaderHamburger from './HeaderHamburger'
import HeaderLoginButton from './HeaderLoginButton'
import styles from '../../Header.module.scss'

export default function SideMenu() {
  const isMobile = useMediaQuery({ maxWidth: 1024 })

  return (
    <div className={styles.sideMenu}>
      <ClassSearch />
      <HeaderEventButton />
      <Link className={styles.B2bLink} to="/promotion-landing/b2bLanding">
        기업교육
      </Link>
      <HeaderLoginButton />
      {isMobile && <HeaderHamburger />}
    </div>
  )
}
