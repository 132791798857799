import TagManager from 'react-gtm-module'
import Lottie from 'react-lottie-player'

import { levelTestAiValues } from '../config'
import styles from '../styles/StepSelectType.module.scss'

export default function StepSelectType({ onClickType, onClickBack }) {
  const onClickTestType = (id, name) => {
    onClickType(id)

    const eventName = {
      quiz: 'ai_leveltest_type_quiz',
      conversation: 'ai_leveltest_type_conversation'
    }[id]

    // window.dataLayer.push({
    //   event: eventName,
    //   product_name: `AI레벨테스트_유형선택_${name}`,
    //   button_id: `levelTestlAi-${id}`
    // })

    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        product_name: `AI레벨테스트_유형선택_${name}`,
        button_id: `levelTestlAi-${id}`
      }
    })
  }

  return (
    <div className={styles.container}>
      <button className={styles.backButton} onClick={onClickBack}>
        <img src={`${levelTestAiValues.HOST_CDN}icon-back.png`} alt="back" />
      </button>
      <p className={styles.label}>
        <span>With Audrey</span>
      </p>
      <h3 className={styles.title}>AI 레벨테스트</h3>
      <p className={styles.description}>어떤 방법으로 테스트를 진행할까요?</p>
      <div className={styles.buttonWrap}>
        {levelTestAiValues.selectType.map((type) => {
          const imageUrl = `${levelTestAiValues.HOST_CDN}image-${type.id}-${'button'}.png`
          return (
            <button
              key={type.id}
              id={`levelTestlAi-${type.id}`}
              className={`button-levelTestlAi-${type.id}`}
              onClick={() => onClickTestType(type.id, type.name)}
            >
              <div className={styles.buttonImage}>
                <img src={imageUrl} alt={type.id} />
                <Lottie
                  className={styles.lottie}
                  animationData={type.lottie}
                  {...levelTestAiValues.lottieDefaultOptions}
                />
              </div>
              <p className={styles.buttonText}>
                {type.name}
                <span>약&nbsp;{type.time}분 소요</span>
              </p>
            </button>
          )
        })}
      </div>
    </div>
  )
}
