import { useState } from 'react'

import useConsultation from 'hooks/useConsultation'

import alertMessage from 'common/alertMessage'
import { getUtm } from 'components/promotion/utile/utile'

export default function useDbCollectionFormLevelTest() {
  // 상담시간, 개인정보 수집 및 마케팅 동의 체크 유무 초기 값
  const initialFormData = {
    privacyAgreeYn: false,
    smsAgreeYn: false
  }
  const [formData, setFormData] = useState(initialFormData)
  const [isOpenModalNotice, setIsOpenModalNotice] = useState(false)
  const [isOpenModalMarketing, setIsOpenModalMarketing] = useState(false)
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [modalControl, setModalControl] = useState({
    success: false,
    duplicate: false
  })

  const { onPostUserConsultation } = useConsultation()

  // 이용약관 모달 토글
  const handleToggleModalNotice = () => {
    setIsOpenModalNotice((prev) => !prev)
  }

  // 마케팅 동의 모달 토글
  const handleToggleModalMarketing = () => {
    setIsOpenModalMarketing((prev) => !prev)
  }

  // 개인정보 수집 및 이용 동의 체크 핸들러
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target
    setFormData((prev) => {
      const newFormData = { ...prev, [id]: checked }
      setIsAllChecked(newFormData.privacyAgreeYn && newFormData.smsAgreeYn)
      return newFormData
    })
  }

  // 전체 동의 체크 핸들러
  const handleAllCheckboxChange = (e) => {
    const { checked } = e.target
    setIsAllChecked(checked)
    setFormData((prev) => ({
      ...prev,
      privacyAgreeYn: checked,
      smsAgreeYn: checked
    }))
  }

  // 상담 데이터 전송 핸들러
  const postConsultationData = (additionalData) => {
    // 모달 닫기
    setIsOpenModalNotice(false)
    setIsOpenModalMarketing(false)
    // // 상담 데이터 전송
    const data = {
      privacyAgreeYn: formData.privacyAgreeYn ? 'Y' : 'N',
      smsAgreeYn: formData.smsAgreeYn ? 'Y' : 'N',
      utm: typeof getUtm === 'undefined' ? '' : getUtm(),
      ...additionalData
    }

    const handleModalToggle = (key) => {
      setModalControl((prev) => ({ ...prev, [key]: !modalControl[key] }))
    }

    onPostUserConsultation(data, (response) => {
      const isDuplication = !response.data.data
      isDuplication ? handleModalToggle('duplicate') : handleModalToggle('success')
      // // 모달 폼 초기화
      setFormData(initialFormData)
      setIsAllChecked(false)
    })
  }

  // 상담 신청 버튼 클릭 핸들러
  // * isPreventMarketingModal: 마케팅 동의 모달 열기 방지 여부(optional)
  const handleSubmit = (data, isPreventMarketingModal) => {
    if (!formData.privacyAgreeYn) {
      alert(alertMessage.FORM_PRIVACY_REQUIRED)
      return
    }
    // 개인정보 수집만 체크된 경우 마케팅 동의 모달 열기
    if (!formData.smsAgreeYn && !isPreventMarketingModal) {
      handleToggleModalMarketing(true)
      return
    }

    // 상담 데이터 전송
    postConsultationData(data)
  }

  const handleModalToggle = (key) => {
    setModalControl((prev) => ({ ...prev, [key]: !modalControl[key] }))
  }

  return {
    modalControl,
    handleModalToggle,
    formData, // 상담 폼 데이터 (개인정보 & 마케팅 동의)
    isOpenModalNotice, // 이용 약관 모달
    handleToggleModalNotice,
    isOpenModalMarketing, // 마케팅 동의 체크 유도 모달
    handleToggleModalMarketing,
    isAllChecked, // 전체 동의 체크 유무
    handleAllCheckboxChange,
    handleCheckboxChange, // 개인정보 수집 및 이용 동의 체크 핸들러
    handleSubmit, // 상담 신청 버튼 클릭 핸들러 (유효성 체크)
    postConsultationData // 상담 데이터 전송 핸들러 (마케팅 모달에서 전송시 사용)
  }
}
