import classNames from 'classnames'
import useHideHeader from 'hooks/useHideHeader'

import SubHeader from './components/subHeader'

import styles from './Header.module.scss'
import useHeader from './hooks/useHeader'

export default function Header() {
  const { headerRef } = useHeader()
  const { isHideHeader, headerHeight } = useHideHeader()
  const top = isHideHeader ? `-${headerHeight + 1}px` : 0

  return (
    <>
      <header ref={headerRef} className={classNames(styles.header)} style={{ top }}>
        <SubHeader />
      </header>
    </>
  )
}
