import { useCallback, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router'

import { throttle } from 'lodash'

import useHeaderStore from 'store/useHeaderStore'

export default function useSubHeaderGnb() {
  const isTable = useMediaQuery({ maxWidth: 1024 })
  const currentSubIndex = useHeaderStore((state) => state.currentSubIndex)
  const gnbItems = useHeaderStore((state) => state.gnbItems)
  const gnbActiveDepthIndex = useHeaderStore((state) => state.gnbActiveDepthIndex)

  const gnbAreaRef = useRef()
  const gnbBoxRef = useRef()

  const [depth2Active, setDepth2Active] = useState(null) // 클릭된 gnb 메뉴
  const [depth2Selected, setDepth2Selected] = useState(null) // 선택된 gnb메뉴
  const [isMoDepth3Chk, setMoDepth3Chk] = useState(false) //gnb 하위메뉴
  const [depthSelected, setDepthSelected] = useState(null)

  const [gnbMovePoint, setGnbMovePoint] = useState(0)
  const [hasPrevButton, setHasPrevButton] = useState(false) // 초기값 false로 설정
  const [hasNextButton, setHasNextButton] = useState(false) // 초기값 false로 설정

  // pc에서 1024 이하의 해상도에서, GNB에서 드래그 기능 구현을 위한 상태값.
  const [hasDragNext, setHasDragNext] = useState(false)
  const [hasDragPrev, setHasDragPrev] = useState(false)
  const [isDrag, setIsDrag] = useState(false)
  const [beginX, setBeginX] = useState()

  const currentPath = useLocation().pathname

  // 3depth 메뉴 리셋
  const onResetActive = () => {
    setDepth2Active(null)
    setMoDepth3Chk(false)
    setDepth2Selected(null)
  }

  const onScroll = () => {
    setMoDepth3Chk(false)
  }

  // mobile 사이즈에서 GNB 2depth 메뉴를 클릭 했을때, 3depth 메뉴를 보여주는 함수입니다.
  const onActiveMobileGnb = (idx) => {
    setDepth2Selected(idx)
    setDepth2Active(idx)
    setMoDepth3Chk(true)
    setDepthSelected(null)
    if (idx === depth2Active && isMoDepth3Chk) {
      onResetActive()
    }
  }

  const onDragStart = (e) => {
    e.preventDefault()
    setIsDrag(true)
    setBeginX(e.pageX + gnbBoxRef.current.scrollLeft)
  }

  const onDragEnd = () => {
    setIsDrag(false)
  }

  const onDragMove = (e) => {
    if (isDrag) gnbBoxRef.current.scrollLeft = beginX - e.pageX
  }
  // GNB 컨텐츠 길이와, 보여지는 영역을 계산, GNB 위치 화살표 노출 여부를 판단하는 함수.
  const autoGnbCheck = useCallback(() => {
    if (gnbBoxRef.current && gnbBoxRef.current?.offsetWidth) {
      const gnbBoxWidth = gnbBoxRef.current.offsetWidth
      const gnbAreaBox = gnbAreaRef.current.offsetWidth - 236 // 236은 사이드 메뉴의 너비로 가정

      if (isTable || gnbAreaBox >= gnbBoxWidth) {
        setHasPrevButton(false)
        setHasNextButton(false)
        setGnbMovePoint(0)
      } else {
        const prevButtonVisible = gnbMovePoint < 0
        const nextButtonVisible = gnbMovePoint > gnbAreaBox - gnbBoxWidth

        setHasPrevButton(prevButtonVisible)
        setHasNextButton(nextButtonVisible)
      }
    }
  }, [gnbMovePoint, isTable])

  // GNB 이동 버튼 클릭 시 실행되는 함수.
  const gnbMoveEvent = (type) => {
    const moveWidth = 100 // 클릭 시 이동되는 gap.
    const movePoint = gnbMovePoint + moveWidth * (type === 'prev' ? 1 : -1)
    const gnbBoxWidth = gnbBoxRef.current.offsetWidth
    const gnbAreaBox = gnbAreaRef.current.offsetWidth - 236
    if (isTable || gnbAreaBox >= gnbBoxWidth) {
      setHasPrevButton(false)
      setHasNextButton(false)
    } else {
      setGnbMovePoint(
        movePoint > 0
          ? 0
          : movePoint > gnbAreaBox - gnbBoxWidth
          ? movePoint
          : gnbAreaBox - gnbBoxWidth
      )
      movePoint >= 0 ? setHasPrevButton(false) : setHasPrevButton(true)
      movePoint < gnbAreaBox - gnbBoxWidth ? setHasNextButton(false) : setHasNextButton(true)
    }
  }

  // [mobile] 드래그 영역에 따라 이전/다음 화살표 show/hide
  const checkMobileNavigation = () => {
    setHasDragPrev(gnbBoxRef.current.scrollLeft > 0)
    setHasDragNext(
      gnbBoxRef.current.scrollWidth >= gnbBoxRef.current.scrollLeft + window.innerWidth + 10
    )
  }

  useEffect(() => {
    if (gnbBoxRef.current) {
      autoGnbCheck() // gnbBoxRef가 설정된 후에 호출
    }
  }, [gnbBoxRef])

  useEffect(() => {
    onResetActive()
  }, [currentSubIndex])

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', autoGnbCheck)
    return () => {
      window.removeEventListener('resize', autoGnbCheck)
    }
  }, [autoGnbCheck, isTable])

  useEffect(() => {
    if (isTable && gnbBoxRef.current) {
      gnbBoxRef.current.addEventListener('scroll', throttle(checkMobileNavigation, 300))
    }
  }, [gnbBoxRef, isTable])

  useEffect(() => {
    setMoDepth3Chk(false)
    if (gnbActiveDepthIndex?.depth2) {
      setDepth2Selected(gnbActiveDepthIndex?.depth2)
    }
  }, [gnbActiveDepthIndex])

  useEffect(() => {
    autoGnbCheck() // 컴포넌트가 마운트될 때 호출
  }, [gnbMovePoint, isTable]) // gnbMovePoint와 isTable이 변경될 때마다 호출

  return {
    hasDragNext,
    hasDragPrev,
    gnbAreaRef,
    gnbMovePoint,
    gnbBoxRef,
    onDragStart,
    onDragMove,
    onDragEnd,
    onActiveMobileGnb,
    depthSelected,
    depth2Selected,
    depth2Active,
    isMoDepth3Chk,
    gnbItems,
    setDepth2Selected,
    currentPath,
    setMoDepth3Chk,
    gnbMoveEvent,
    hasPrevButton,
    hasNextButton,
    currentSubIndex
  }
}
