import { useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'

import useGetGnbList from 'hooks/useGetGnbList'

import useHeaderHeightStore from 'store/useHeaderHeightStore'
import useHeaderStore from 'store/useHeaderStore'

import { headerValues } from '../config'

export default function useHeader() {
  const { gnbItems } = useGetGnbList()
  const currentPath = useLocation().pathname
  const headerRef = useRef(null)
  let preScrollTop = useRef(0)

  const setHeaderHeight = useHeaderHeightStore((state) => state.setHeaderHeight)
  const setIsHideHeader = useHeaderHeightStore((state) => state.setIsHideHeader)
  const headerHeight = useHeaderHeightStore((state) => state.headerHeight)
  const isMain = useHeaderStore((state) => state.isMain)
  const onChangeCurrentSubIndex = useHeaderStore((state) => state.onChangeCurrentSubIndex)
  const currentSubIndex = useHeaderStore((state) => state.currentSubIndex)
  const onChangeGnbActiveDepthIndex = useHeaderStore((state) => state.onChangeGnbActiveDepthIndex)
  const onChangeIsMain = useHeaderStore((state) => state.onChangeIsMain)

  const onChangeSubIndex = useCallback(
    (pagePath) => {
      let activeDepthBoolen = true
      gnbItems?.forEach((item, idx) => {
        item.childGnbMenus.forEach((subItem, subIdx) => {
          if (pagePath === item.link) {
            onChangeCurrentSubIndex(idx)
            onChangeIsMain(false)
            onChangeGnbActiveDepthIndex({
              depth1: idx,
              depth2: subIdx,
              depth3: 0
            })
            activeDepthBoolen = false
            return false
          }
        })
      })

      if (activeDepthBoolen) {
        onChangeGnbActiveDepthIndex({ depth1: 0, depth2: 0, depth3: 0 })
      }
    },
    [gnbItems, onChangeCurrentSubIndex, onChangeGnbActiveDepthIndex, onChangeIsMain]
  )
  const gnbActiveCheck = useCallback(() => {
    onChangeSubIndex(currentPath)
  }, [currentPath, onChangeSubIndex])

  // 특정URL 진입시 active하고 싶은 GNB메뉴와 매치시키는 함수
  const checkUrlMatch = useCallback(() => {
    headerValues.urlCustomActiveList.forEach((item) => {
      if (currentPath.indexOf(item.pagePath) !== -1) onChangeSubIndex(item.matchUrl)
    })
  }, [currentPath, onChangeSubIndex])

  useEffect(() => {
    gnbActiveCheck()
    checkUrlMatch()
  }, [gnbItems, currentPath, gnbActiveCheck, checkUrlMatch])

  // 헤더가 변경될 때 마다 높이값 가져와서 store 저장하는 함수
  useEffect(() => {
    const headerElement = headerRef.current
    if (!headerElement) return
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0]
      const newHeight = entry.contentRect.height

      if (headerHeight !== newHeight) {
        window.requestAnimationFrame(() => {
          setHeaderHeight(newHeight)
        })
      }
    })
    resizeObserver.observe(headerElement)
    return () => {
      resizeObserver.unobserve(headerElement)
    }
  }, [headerHeight, setHeaderHeight])

  /**
   * 스크롤에 따라 헤더 숨김 처리하는 함수
   * scroll down -> 헤더 숨김
   * scroll up -> 헤더 보임
   */
  const handleHideGnb = useCallback(() => {
    let currentScrollTop = window.scrollY
    if (currentScrollTop > 50) {
      if (preScrollTop.current < currentScrollTop) {
        setIsHideHeader(true)
      } else {
        setIsHideHeader(false)
      }
      preScrollTop.current = currentScrollTop
    }
  }, [setIsHideHeader])

  useEffect(() => {
    let timer = null
    setInterval(() => {
      document.addEventListener('scroll', handleHideGnb)
    })
    return () => {
      clearInterval(timer)
      document.removeEventListener('scroll', handleHideGnb)
    }
  }, [handleHideGnb])

  return { headerRef, currentSubIndex, isMain }
}
