import React, { useState, useEffect, useReducer } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'

import api from 'common/api'
import MobileHeader from 'components/common/MobileHeader'

import NoContentButtons from './components/NoContentButtons'

import styles from './Home.module.scss'
import TabMain from './TabMain'
import Loading from '../../components/common/Loading'
import Lnb from '../../components/Lnb'
import MyClassList from '../../components/MyClassList'
import NoContent from '../../components/NoContent'

import '../../assets/styles/myClass.scss'
const icoImg = 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_no_class.png'

export default function MyClass() {
  const [isLoading, setLoading] = useState(true)
  const [myClassData, setMyClassData] = useState([])
  const [oldClassCount, setOldClassCount] = useState(0)
  const navigate = useNavigate()

  // 3.0 + 2.0 클래스
  const getMyclasssListCLS = () => {
    setLoading(true)
    api
      .get('/v2/myClass/web')
      .then((response) => {
        setMyClassData(response.data.data.classes)
        setOldClassCount(response.data.data.oldClassCount)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getMyclasssListCLS()
  }, [])

  return (
    <div className="myclass-col">
      <div className="inner-col">
        <div className="container mypage-sub">
          <MobileHeader title="MY 클래스" onClick={() => navigate('/mypage/1')} />
          <Lnb />
          <section className="content">
            {/* 상단 탭 영역 컴포넌트로 분리 - 전달값으로 active 처리 (MYCLASS / MYHISTORY) */}
            <TabMain activeTab={'MYCLASS'} />
            <section className="myclass-content-box">
              <div className="tab-content active">
                {isLoading && isLoading ? (
                  <div className={styles.loadingBox}>
                    <Loading />
                  </div>
                ) : oldClassCount > 0 || myClassData.length > 0 ? (
                  <ClassAccordionList myClassData={myClassData} />
                ) : (
                  <div className={`class-empty myclass ${styles.empty}`}>
                    <NoContent icoImg={icoImg} titleMsg={'수강중인 클래스가 없습니다.'}>
                      <NoContentButtons />
                    </NoContent>
                  </div>
                )}
              </div>
            </section>
          </section>
        </div>
      </div>
    </div>
  )
}

function ClassAccordionList({ myClassData }) {
  const [isToggle, setIsToggle] = useState({ srr: false, pc: false })
  const [isSelected, setSelected] = useState('srr')
  const isMobile = useMediaQuery({ maxWidth: 1024 })

  if (myClassData.length === 0) return null

  const srrData = myClassData.filter((item) => item.packageTypeCode === 'SRR')
  const pcSupportData = myClassData.filter((item) => item.packageTypeCode !== 'SRR')

  const hasBoth = pcSupportData.length > 0 && srrData.length > 0

  const handleSelectChange = (e) => {
    setSelected(e.target.value)
  }

  const shouldRenderList = (isMobile, hasBoth, isSelected, type, dataLength) => {
    if (isMobile) {
      return hasBoth && isSelected === type && dataLength > 0
    }
    return dataLength > 0
  }

  const handleToggleBtnClick = (type) => {
    const updatedObj = Object.entries(isToggle).map(([key, value]) => [
      key,
      type === key ? !value : false
    ])

    setIsToggle(Object.fromEntries(updatedObj))
  }

  return (
    <>
      <div className="class-content">
        {/* AI SRR & 야나두 학습 앱 전용 상품 모두 있는 경우 */}
        {isMobile && hasBoth && (
          <select onChange={handleSelectChange} value={isSelected}>
            <option value="srr">AI 학습지</option>
            <option value="pc-support">야나두 학습 앱 전용 상품</option>
          </select>
        )}

        {shouldRenderList(isMobile, hasBoth, isSelected, 'srr', srrData.length) && (
          <ClassAccordionItem
            myClassData={srrData}
            isSrr={true}
            isMobile={isMobile}
            onToggleBtnClick={handleToggleBtnClick}
            isOpen={isToggle.srr}
          />
        )}

        {shouldRenderList(isMobile, hasBoth, isSelected, 'pc-support', pcSupportData.length) && (
          <ClassAccordionItem
            myClassData={pcSupportData}
            isSrr={false}
            isMobile={isMobile}
            onToggleBtnClick={handleToggleBtnClick}
            isOpen={isToggle.pc}
          />
        )}
      </div>
    </>
  )
}

function ClassAccordionItem({ myClassData, isSrr, isMobile, onToggleBtnClick, isOpen }) {
  const title = isSrr ? 'AI 학습지' : 'PC 수강 지원'
  const description = isSrr
    ? 'AI 학습지는 iPad 전용 앱에서만 수강하실 수 있습니다. iPad에서 App Store를 이용해 주세요.'
    : '야나두 학습 앱 전용 상품 이용자의 학습 편의 향상을 위해 PC에서 동영상 강의 수강을 지원하고 있습니다.'

  const mobileDescription = isSrr ? (
    'AI 학습지는 iPad 전용 앱에서만 수강하실 수 있습니다. iPad에서 App Store를 이용해 주세요.'
  ) : (
    <>
      야나두 학습 앱에서 수강하실 수 있습니다. <br /> 앱을 다운로드 받고 다양한 서비스를 이용해
      보세요.
    </>
  )

  return (
    <div className={'class-box myclass' + (isOpen ? ' active' : '')}>
      <div className={isSrr ? 'inner is-srr-only' : 'inner is-pc-support'}>
        <p>
          <strong>{title}</strong>
          {isMobile ? mobileDescription : description}
        </p>

        {isMobile && !isSrr && (
          <div className="download-btn-area">
            <button
              type="button"
              className="btn-aos"
              onClick={() =>
                window.open('https://play.google.com/store/apps/details?id=kr.co.yanadoo.app2')
              }
            >
              <i className="blind">AOS 다운로드</i>
            </button>

            <button
              type="button"
              className="btn-ios"
              onClick={() => window.open('https://itunes.apple.com/kr/app/id1539582420')}
            >
              <i className="blind">IOS 다운로드</i>
            </button>
          </div>
        )}

        <button
          type="button"
          className="btn-toggle"
          onClick={() => onToggleBtnClick(isSrr ? 'srr' : 'pc')}
        >
          <span>자세히 보기</span>
        </button>
      </div>
      {!isMobile && isOpen && !isSrr && <QRCode />}
      <MyClassList myClassData={myClassData} tabType={'DEFAULT'} dataType={'ALL'} />
    </div>
  )
}

function QRCode() {
  return (
    <div className="qr-code-area">
      <hgroup>
        <h4>야나두 학습 앱에서도 수강하실 수 있습니다.</h4>
        <p>
          야나두 앱을 다운로드 받고 <br />
          앱에서만 제공하는 다양한 학습 서비스를 이용해보세요.
        </p>
      </hgroup>

      <img
        src="https://english.yanadoocdn.com/upload/yanadoo/pc/myClass/2102/img_app_code2.png"
        alt="앱 다운로드 QR"
      ></img>
    </div>
  )
}
