import React, { useState, useEffect, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useMediaQuery } from 'react-responsive'
import { useParams, useLocation } from 'react-router-dom'

import api from 'common/api'
import { scrollMove } from 'common/common'
import 'react-loading-skeleton/dist/skeleton.css'
import ImgView from 'components/ImgView'
import Pagination from 'components/Pagination'

import AdminAnswer from './AdminAnswer'
import AttachmentLink from './AttachmentLink'
import AudioPlayer from './AudioPlayer'
import ButtonList from './ButtonList'
import Comment from './Comment'
import styles from './DetailBoard.module.scss'
import FileDownLoad from './FileDownLoad'
import ThumbNail from './ThumbNail'

export default function DetailBoard({ type }) {
  const location = useLocation()
  const param = location.search
  const { currentSeq } = useParams()
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const [pageIdx, setPageIdx] = useState(1)
  const [totalPages, setTotalPages] = useState()
  const [communityInfo, setCommunityInfo] = useState(null)
  const [commentData, setCommentData] = useState(null)

  const [commentRegistTime, setCommentRegistTime] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [imageFlag, setImageFlag] = useState(false)

  const commentListBoxRef = useRef(null)

  const boardDetailInfo = {
    FREE: {
      title: '수강생 Talk',
      apiContentUrl: '/v2/community-tab/community/post?communityPostSeq=',
      apiCommentUrl: '/v2/community-tab/community/comment/list?communityPostSeq=',
      apiPrevNextUrl: '/v2/community-tab/community/move-and-post?communityPostSeq='
    },
    STUDY: {
      title: '학습 Q&A',
      apiContentUrl: '/v2/community-tab/lecture-question/detail?questionSeq=',
      apiPrevNextUrl: '/v2/community-tab/lecture-question/move?questionSeq='
    },
    SRR: {
      title: '스르르 학습지 Talk',
      apiContentUrl: '/v2/community-tab/community/post?communityPostSeq=',
      apiCommentUrl: '/v2/community-tab/community/comment/list?communityPostSeq=',
      apiPrevNextUrl: '/v2/community-tab/community/move-and-post?communityPostSeq='
    }
  }[type]

  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const handleCurrentIdx = (idx) => {
    setPageIdx(idx)
  }

  // 커뮤니티 상세 정보
  const getCommunityInfo = (seq) => {
    api
      .get(`${boardDetailInfo.apiContentUrl}${seq}`)
      .then((response) => {
        setCommunityInfo(response.data.data)
        setImageFlag(response.data.data?.attachments?.[0].type === 'IMAGE')
        setIsLoading(true)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 댓글 조회 API
  const getCommentData = (seq) => {
    api
      .get(`${boardDetailInfo.apiCommentUrl}${seq}&pageIndex=${pageIdx}&pageSize=10`)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setCommentData(response.data.data?.comments)
          if (pageIdx === 1) {
            setTotalPages(response.data.data?.totalPage)
          }
          setCommentRegistTime(response.data.meta.dateTime.replace('-', '/').replace('-', '/'))
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getCommunityInfo(currentSeq)
    if (type !== 'STUDY') {
      getCommentData(currentSeq)
    }
    setIsLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSeq])

  useEffect(() => {
    if (type !== 'STUDY') {
      getCommentData(currentSeq)
    }
    if (commentListBoxRef.current) {
      scrollMove(
        0,
        commentListBoxRef.current.getBoundingClientRect().top + window.pageYOffset - 150
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIdx])

  return (
    <div className={styles.smInnerBox}>
      <div className={styles.detailBoardBox}>
        <h3>{boardDetailInfo.title}</h3>
        <div className={styles.titleBox}>
          {type === 'STUDY' ? (
            <p>
              {isLoading ? communityInfo.classes.name : <Skeleton width={'100%'} />}
              {communityInfo?.isReply && <strong>답변완료</strong>}
            </p>
          ) : (
            <p>{isLoading ? communityInfo?.community?.title : <Skeleton width={'100%'} />}</p>
          )}
          <p>{isLoading ? communityInfo?.title : <Skeleton />}</p>
          <div>
            <div className={styles.profileBox}>
              <ThumbNail backgroundImage={communityInfo?.registUser?.profileImageUrl} />
              <strong>
                {isLoading ? (
                  communityInfo.registUser.nickName
                ) : (
                  <Skeleton width={deviceCheck ? '20rem' : '10rem'} />
                )}
              </strong>
            </div>
            <div className={styles.infoBox}>
              {isLoading && (
                <>
                  <span>
                    {communityInfo.registDate.replace('-', '.').replace('-', '.')}
                    <i>&middot;</i>
                  </span>
                  <span>조회수 {addComma(communityInfo.hitCount)}</span>
                  {type !== 'STUDY' && (
                    <span>
                      <i>&middot;</i>좋아요 {communityInfo?.likeCount}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.contentBox}>
          <p>{communityInfo?.contents || <Skeleton />}</p>
          {communityInfo &&
            communityInfo.attachments &&
            type !== 'STUDY' &&
            communityInfo.attachments.map((attach, idx) => {
              return (
                <div key={idx}>
                  {attach.type === 'LINK' ? <AttachmentLink data={attach} /> : undefined}
                  {attach.type === 'AUDIO' ? <AudioPlayer data={attach} /> : undefined}
                  {attach.type === 'ALL' ? <FileDownLoad data={attach} /> : undefined}
                </div>
              )
            })}
          {type === 'STUDY' && isLoading && communityInfo.attachment?.url && (
            <img src={communityInfo.attachment.url} alt="학습관리자에서 등록한 이미지" />
          )}
          {imageFlag && (
            <div className={styles.imgViewBox} style={{ marginTop: deviceCheck ? '5rem' : '3rem' }}>
              <ImgView data={communityInfo} isLoading={isLoading} imageFlag={imageFlag} />
            </div>
          )}
          {type !== 'STUDY' && (
            <p className={styles.commentCountItem}>댓글 {communityInfo?.commentCount}</p>
          )}
        </div>
        {communityInfo && communityInfo?.isReply && (
          <AdminAnswer
            data={communityInfo.answer}
            deviceCheck={deviceCheck}
            backgroundColor={'#F7F7FC'}
          />
        )}
        {commentData && (
          <div className={styles.commentListBox} ref={commentListBoxRef}>
            {commentData.map((item, idx) => {
              return (
                <Comment
                  deviceCheck={deviceCheck}
                  data={item}
                  key={idx}
                  commentRegistTime={commentRegistTime}
                  isLoading={isLoading}
                ></Comment>
              )
            })}
            {totalPages >= 2 && (
              <Pagination
                type={'IDX'}
                handleCurrentIdx={handleCurrentIdx}
                totalPages={totalPages}
                limitPage={5}
                maxNum={5}
                minNum={0}
                pathName={`/community/free/detail/${currentSeq}`}
                pageIdx={pageIdx}
                jumpingPrevBtn={true}
                jumpingNextBtn={true}
              />
            )}
          </div>
        )}
        <ButtonList
          type={type}
          handleCurrentIdx={handleCurrentIdx}
          currentSeq={currentSeq}
          boardDetailInfo={boardDetailInfo}
          param={param}
        />
      </div>
    </div>
  )
}
