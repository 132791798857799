export const memberValues = {
  reasonCheckbox: [
    {
      id: '1',
      name: '컨텐츠가 부족하고 강의도 만족스럽지 않아요',
      key: 'checkBox1',
      label: 'Check Box 1'
    },
    {
      id: '2',
      name: '난이도가 저와 안 맞아요',
      key: 'checkBox2',
      label: 'Check Box 2'
    },
    {
      id: '3',
      name: '서비스를 이용하기 너무 힘들어요',
      key: 'checkBox3',
      label: 'Check Box 3'
    },
    {
      id: '4',
      name: '구매 가격이 불만이에요',
      key: 'checkBox4',
      label: 'Check Box 4'
    },
    {
      id: '5',
      name: '기타의견',
      key: 'checkBox5',
      label: 'Check Box 5'
    }
  ],
  precautions: {
    pc: [
      '회원 탈퇴 시 보유 중인 강의, 쿠폰은 모두 소멸되며 재가입시 복구가 불가합니다.',
      '회원 탈퇴 시 모든 정보는 삭제됩니다. 회원 탈퇴 후 문의사항은 야나두 학습운영센터(1600-0563)로 연락주십시오.',
      '탈퇴가 처리되면 동일한 ID로 재가입이 불가합니다.',
      '회원정보 및 포인트, 구매내역은 바로 삭제되며 포인트는 현금으로 환불 불가합니다.',
      '회원 탈퇴 시 틈새단어 등 야나두 회원전용 서비스 또한 사용불가 합니다.'
    ],
    mo: [
      '회원 정보, 포인트, 구매내역 즉시 삭제 (포인트 환불 불가)',
      '모든 정보는 삭제되며 동일한 ID로 재가입 불가',
      '보유 중인 강의와 쿠폰은 모두 소멸되며 재가입 시 복구 불가',
      '틈새단어 등 회원전용 서비스 사용불가',
      '회원 탈퇴 후 문의 : 야나두 학습 운영센터(1600-0563) '
    ]
  },
  reasonPlaceholder: {
    pc: '기타 의견을 작성해주세요.',
    mo: '기타 의견 혹은 야나두에게 바라는 점이 있다면 자유롭게 남겨주세요.'
  }
}
