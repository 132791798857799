import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import classNames from 'classnames'
import { postGAEvent } from 'utils/postGAEvent'

import useHeaderStore from 'store/useHeaderStore'

import styles from '../../styles/SubHeader.module.scss'

export default function SubHeaderCompany({ gnbInfoSet }) {
  const gnbItems = useHeaderStore((state) => state.gnbItems)
  const currentSubIndex = useHeaderStore((state) => state.currentSubIndex)
  const onChangeCurrentSubIndex = useHeaderStore((state) => state.onChangeCurrentSubIndex)

  const [activeIndex, setActiveIndex] = useState(currentSubIndex)

  const companyItem = useMemo(() => {
    return gnbInfoSet(activeIndex)
  }, [activeIndex, gnbInfoSet])

  const onChangeActiveIndex = (index) => {
    setActiveIndex(index)
  }

  useEffect(() => {
    setActiveIndex(currentSubIndex)
  }, [currentSubIndex])

  const onClickGnbMenu = (idx, gaItem) => {
    onChangeCurrentSubIndex(idx)
    postGAEvent({ ...gaItem })
  }

  return (
    <div
      className={styles.subHeaderCompany}
      style={{ backgroundColor: companyItem ? companyItem[0] : '' }}
    >
      <div className={styles.inner}>
        <strong className={styles.companyDescription}>{companyItem ? companyItem[1] : ''}</strong>
        <ul className={styles.companyList}>
          {gnbItems &&
            gnbItems?.map((item, idx) => (
              <li
                key={idx}
                className={classNames(styles.companyItem, activeIndex === idx && styles.active)}
              >
                <Link
                  className={styles.companyItemLink}
                  to={item.link}
                  onClick={() => onClickGnbMenu(idx, item.menuName)}
                  onMouseOver={() => onChangeActiveIndex(idx)}
                  onMouseOut={() => onChangeActiveIndex(currentSubIndex)}
                  target={item.target}
                >
                  {item.menuName}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}
