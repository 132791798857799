import { isMobile } from 'react-device-detect'
import TagManager from 'react-gtm-module'

const KAKAO_TALK_LINK = 'https://pf.kakao.com/_xeWxjMu'
const KAKAO_TALK_SRC =
  'https://english.yanadoocdn.com/upload/yanadoo/assets/images/ico_kakaotalk_250305.png'

export default function KaKaoTalk() {
  const handleClickKakaoTalk = () => {
    // window.dataLayer.push({
    //   event: 'kakao-mo-bottom-CTA',
    //   product_name: '영어_카카오톡_상담하기_mo',
    //   button_id: 'kakaoFloatingBtn'
    // })

    TagManager.dataLayer({
      dataLayer: {
        event: 'kakao-mo-bottom-CTA',
        product_name: '영어_카카오톡_상담하기_mo',
        button_id: 'kakaoFloatingBtn'
      }
    })
  }

  return (
    isMobile && (
      <a
        id="kakaoFloatingBtn"
        href={KAKAO_TALK_LINK}
        target="_blank"
        rel="noreferrer"
        onClick={handleClickKakaoTalk}
        style={{
          display: 'block',
          width: '55px',
          height: '55px'
        }}
      >
        <img src={KAKAO_TALK_SRC} alt="카카오톡 상담" />
      </a>
    )
  )
}
