import React, { useState, useRef } from 'react'
import '../../assets/styles/modal.scss'
import TagManager from 'react-gtm-module'

import api from 'common/api'

export default function ModalCourseInquiry({ btnClose, handleCloseModal }) {
  const [inputValue, setInputValue] = useState('')
  const companyNameRef = useRef()
  const managerRef = useRef()
  const numberRef = useRef()
  const emailRef = useRef()
  const agreeRef = useRef()
  const coursePeriodRef = useRef()
  const contentRef = useRef()

  const handleOnlyNumber = (e) => {
    const regex = /^[0-9\b -]{0,11}$/
    if (regex.test(e.target.value)) {
      setInputValue(e.target.value)
    }
  }

  let delayCheck = false

  const handleSubmitBtn = () => {
    if (companyNameRef.current.value.trim().length === 0) {
      alert('회사명/단체명을 입력해주세요.')
      companyNameRef.current.focus()
      return false
    }

    if (managerRef.current.value.trim().length === 0) {
      alert('신청자를 입력해주세요.')
      managerRef.current.focus()
      return false
    }

    if (numberRef.current.value.trim().length === 0) {
      alert('연락처를 입력해주세요.')
      numberRef.current.focus()
      return false
    }

    if (emailRef.current.value.trim().length === 0) {
      alert('이메일을 입력해주세요.')
      emailRef.current.focus()
      return false
    }

    if (!agreeRef.current.checked) {
      alert('개인정보 수집에 동의해주세요.')
      return false
    }

    if (delayCheck) {
      alert('잠시 후에 시도해 주세요')
    } else {
      delayCheck = true
      postConsultationB2b()
    }
  }

  // 단체수강 문의 등록 API
  const postConsultationB2b = () => {
    const data = {
      companyName: companyNameRef.current.value, // 회사명/단체명
      userName: managerRef.current.value, // 신청자
      phone: numberRef.current.value, // 연락처
      email: emailRef.current.value, // 이메일
      coursePeriod: coursePeriodRef.current.value, // 수강기간/수강인원
      content: contentRef.current.value // 문의내용 및 요청사항
    }
    api
      .post('/v2/inquiry/consultation/b2b', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)
          delayCheck = false
          return false
        }
        // window.dataLayer.push({
        //   event: 'b2b_lead',
        //   product_name: 'b2b_lead'
        // })
        TagManager.dataLayer({
          dataLayer: {
            event: 'b2b_lead',
            product_name: 'b2b_lead'
          }
        })
        alert('단체 수강 문의가 완료되었습니다.')
        delayCheck = false
        window.location.href = window.location.href
          .replace('?courseInquiry', '')
          .replace('&courseInquiry', '')
      })
      .catch((e) => {
        console.log(e)
        delayCheck = false
      })
  }

  return (
    <>
      <div className="modal-wrap">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>단체수강 문의</strong>
          </div>
          <div className="modal-content">
            <div className="modal-form-col inquiry">
              <dl>
                <dt className="ico-red-dot">회사명&#47;단체명</dt>
                <dd>
                  <input
                    ref={companyNameRef}
                    type="text"
                    placeholder="회사명을 입력해주세요."
                    maxLength="15"
                  />
                </dd>
              </dl>
              <dl>
                <dt className="ico-red-dot">신청자</dt>
                <dd>
                  <input
                    ref={managerRef}
                    type="text"
                    placeholder="신청자를 입력해주세요."
                    maxLength="15"
                  />
                </dd>
              </dl>
              <dl>
                <dt className="ico-red-dot">연락처</dt>
                <dd>
                  <input
                    value={inputValue}
                    onChange={handleOnlyNumber}
                    ref={numberRef}
                    type="text"
                    placeholder="연락처를 입력해주세요."
                  />
                </dd>
              </dl>
              <dl>
                <dt className="ico-red-dot">이메일</dt>
                <dd>
                  <input
                    ref={emailRef}
                    type="text"
                    placeholder="이메일을 입력해주세요."
                    maxLength="25"
                  />
                </dd>
              </dl>
              <dl>
                <dt>수강기간&#47;수강인원</dt>
                <dd>
                  <input
                    ref={coursePeriodRef}
                    type="text"
                    placeholder="수강기간/수강인원을 입력해주세요."
                    maxLength="15"
                  />
                </dd>
              </dl>
              <dl>
                <dt>문의내용 및 요청사항</dt>
                <dd>
                  <textarea
                    ref={contentRef}
                    placeholder="문의내용을 입력해주세요"
                    className="basic-textarea"
                  ></textarea>
                </dd>
              </dl>
              <dl>
                <dt>개인정보 수집&#47;이용에 대한 동의</dt>
                <dd>
                  <div className="scroll-box">
                    야나두는 귀하의 단체수강 문의에 대한 처리 및 회신을 위하여 귀하의 개인정보를
                    수집 및 처리하고자 합니다. 내용을 자세히 읽으신 후 동의여부를 결정하여 주시기
                    바랍니다.
                    <br />
                    <br />
                    1. 개인정보 수집 이용목적
                    <br />
                    &#183; b2b 기업 및 단체수강신청 내용의 확인 및 처리
                    <br />
                    2. 개인정보 수집 항목
                    <br />
                    &#183; 회사명/단체명, 신청자명, 연락처, 이메일, 문의내용
                    <br />
                    3. 개인정보 이용기간 및 보유기간
                    <br />
                    &#183; 상담 완료 후 파기
                    <br />
                    4. 동의를 거부할 권리 및 동의 거부에 따른 불이익
                    <br />
                    &#183; 귀하는 개인정보 수집 및 이용동의에 대하여 동의를 거부할 권리가 있으며,
                    동의를 거부하는 경우 상담신청이 불가능합니다.
                  </div>
                </dd>
              </dl>
              <label htmlFor="agree" className="basic-input-check">
                <input ref={agreeRef} id="agree" type="checkbox" />
                <i className="ico-checkbox"></i>
                <span>개인정보 수집 및 이용에 동의합니다.</span>
              </label>
              <div className="btn-flex-form">
                <button className="btn active" onClick={handleSubmitBtn}>
                  <span>문의하기</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
