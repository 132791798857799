import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import moment from 'moment'

import useLevelTestAiStore from 'store/useLevelTestAiStore'

import useLevelTestAiCheckLimitation from './useLevelTestAiCheckLimitation'

// 테스트 - 퀴즈형
export default function useLevelTestAi() {
  const location = useLocation()
  const navigation = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const examTypeParams = searchParams.get('examType')

  const [step, setStep] = useState('selectType')
  const [survey, setSurvey] = useState(null)
  const [startTime, setStartTime] = useState(null)

  const setExamType = useLevelTestAiStore((state) => state.setExamType)
  const setAnswers = useLevelTestAiStore((state) => state.setAnswers)
  const setRecommend = useLevelTestAiStore((state) => state.setRecommend)
  const setTotalTime = useLevelTestAiStore((state) => state.setTotalTime)

  const { checkLimitation } = useLevelTestAiCheckLimitation()

  const onClickBackType = () => {
    navigation('/levelTestAi/start')
  }
  /**
   * 타입 선택 후 테스트 시작 버튼 클릭
   * > 로그인 체크
   * > 각 유형당 하루에 2번씩 테스트 가능한지 체크
   */

  const onClickType = (type) => {
    checkLimitation(type, () => onMoveToNote(type))
  }

  // 유형 선택시 > 시작전 안내사항 모달로 이동
  const onMoveToNote = (type) => {
    setExamType(type)
    setStep('note')
  }

  // 시작전 안내사항 모달 > 취소 클릭시 (횟수 초기화)
  const onClickCancelNote = () => {
    setStep('selectType')
  }

  const onClickMoveToMain = () => {
    navigation('/levelTestAi/start')
  }

  // 시작전 안내사항 모달 > 테스트시작 클릭시 (시작시간 저장 & 테스트 스텝이동)
  const onClickConfirmNote = () => {
    setStep('exam')
    setStartTime(new Date())
  }

  // 문제풀이 나가기 클릭시
  const onQuitExam = () => {
    setStep('selectType')
  }

  // 문제풀이 로드 실패시 유의사항 화면으로 이동
  const onFailLoadAvatar = () => {
    setStep('note')
  }

  // 문제풀이 완료 했을경우 > 설문조사 페이지로 이동 & 소요시간과 답안 저장
  const onCompleteExam = (answer) => {
    const diff = new Date() - startTime
    const diffTime = moment(diff).format('mm:ss')
    setAnswers(answer)
    setTotalTime(diffTime)
    setStep('survey')
  }

  // 설문조사 완료 후 > 결과 분석중 페이지로 이동
  const onCompleteSurvey = (result) => {
    setSurvey(result)
    setRecommend(result.recommended)
    setStep('analyzing')
  }

  // 결과 분석 완료 후 > 내부상담 신청 페이지로 이동
  const onCompleteAnalyzing = () => {
    setStep('consultation')
  }

  useEffect(() => {
    window['wingBannerDisplay']()
  }, [])

  useEffect(() => {
    if (examTypeParams) {
      setExamType(examTypeParams)
      setStep('note')
    }
  }, [examTypeParams, setExamType])

  return {
    step,
    survey,
    onClickCancelNote,
    onClickConfirmNote,
    onClickType,
    onQuitExam,
    onCompleteExam,
    onCompleteSurvey,
    onCompleteAnalyzing,
    onClickMoveToMain,
    onClickBackType,
    onFailLoadAvatar
  }
}
