import { memo } from 'react'

import useAlertBeforeUnload from 'hooks/useAlertBeforeUnload'

import useLevelTestAiStore from 'store/useLevelTestAiStore'

import { levelTestAiValues } from '../config'
import Conversation from './Conversation'
import ExamLoading from './ExamLoading'
import Quiz from './Quiz'
import useLevelTestAiKlleon from '../hooks/useLevelTestAiKlleon'
import styles from '../styles/StepExam.module.scss'

const constructImageUrl = (path) => `${levelTestAiValues.HOST_CDN}${path}`

function StepExam({ onQuitExam, onFailLoadAvatar, onCompleteExam }) {
  const examType = useLevelTestAiStore((state) => state.examType)
  const { avatarRef, isLoading } = useLevelTestAiKlleon(onFailLoadAvatar)

  // 페이지 떠날 때 경고
  useAlertBeforeUnload('레벨테스트를 종료하시겠어요?')

  return (
    <div className={styles.container}>
      {isLoading ? (
        <ExamLoading examType={examType} onClickCancel={onQuitExam} />
      ) : (
        <>
          <button className={styles.exitButton} onClick={onQuitExam}>
            <img src={constructImageUrl('icon-exam-exit.png')} alt="exit" />
          </button>
          {
            {
              quiz: <Quiz onCompleteExam={onCompleteExam} />,
              conversation: <Conversation onCompleteExam={onCompleteExam} />
            }[examType]
          }
        </>
      )}
      <div className={styles.chat}>
        <avatar-container ref={avatarRef} />
      </div>
    </div>
  )
}

export default memo(StepExam)
