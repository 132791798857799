import React, { useState, useEffect, useCallback } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate } from 'react-router-dom'

import 'react-loading-skeleton/dist/skeleton.css'
import moment from 'moment'
import queryString from 'query-string'

import api from 'common/api'
import { addComma } from 'common/common'
import BoardBanner from 'components/board/BoardBanner'
import BoardListItem from 'components/board/BoardListItem'
import BoardSearch from 'components/board/BoardSearch'
import InfoTextBox from 'components/board/InfoTextBox'
import NoContent from 'components/NoContent'
import Pagination from 'components/Pagination'

import styles from './Board.module.scss'
import BoardSkeleton from './BoardSkeleton'
import { communityValues } from './config'
import TabBoard from './TabBoard'
const icoImg = 'https://english.yanadoocdn.com/upload/yanadoo/assets/images/'

// 커뮤니티 게시판 컴포넌트 입니다.
// 게시판 타입을 props 키워드로 받고, 해당 키워드에 맞는 게시물 목록을 노출합니다.
// 현재 연동중인 게시판 리스트. (게시판 추가 시 하위 데이터 수정)
// FREE: 수강생 Talk, STUDY: 학습 Q&A, SRR: 스르르 학습지 Talk
export default function Board({ type }) {
  // 각 게시판별 상세 정보 (필수값인 경우 * 표기해둠)

  const location = useLocation()
  const navigation = useNavigate()
  const seqSRR = 108
  const { communitySeq, pageIndex, searchType, searchWord } = queryString.parse(location.search)

  const boardInfo = communityValues.boardInfo[type]
  const [contentsData, setContentsData] = useState([])
  const [searchSelect, setSearchSelect] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [totalPageSize, setTotalPageSize] = useState(1)

  const isSearchMode = searchType && searchWord
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const pageSize = deviceCheck ? 5 : 10

  // 목록 필터값이 변경됬을 경우 > navigate
  // pIndex : 현재 페이지 / sType : 검색 타입 / sWord :검색어
  const onChangeFilter = ({ pIndex, sType, sWord }) => {
    const params = queryString.stringify(
      {
        pageIndex: pIndex,
        searchType: sWord ? sType : null,
        searchWord: sWord ? decodeURIComponent(sWord) : null
      },
      { skipNull: true }
    )

    navigation(`/community/${type.toLowerCase()}/list?${params}`)
  }

  // 페이지네이션 클릭시 페이지 이동
  const onChangePagination = (idx) => {
    if (idx === Number(pageIndex)) return // (첫번째 페이지일 경우 중복 호출 방지)
    onChangeFilter({ pIndex: idx, sType: searchType, sWord: searchWord })
  }

  // [API] 검색 타입 리스트 호출 (하단 검색 selectbox)
  const getSearchSelectList = useCallback(() => {
    if (boardInfo.apiSearchSelectList !== undefined) {
      api
        .get(boardInfo.apiSearchSelectList)
        .then((response) => {
          const data = response.data.data
          setSearchSelect(type === `STUDY` ? data.searchTypes : data)
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      setSearchSelect([
        { description: `제목`, searchType: `TITLE` },
        { description: `작성자`, searchType: `REGISTER_NAME` }
      ])
    }
  }, [boardInfo.apiSearchSelectList, type])

  // [API] 총 페이지 수 호출
  const getTotalPage = useCallback(
    (itemCount) => {
      const params = queryString.stringify(
        {
          communitySeq: type === 'SRR' ? seqSRR : communitySeq > 0 ? communitySeq : undefined,
          searchType: searchType,
          searchWord: searchWord
        },
        { skipNull: true }
      )

      api
        .get(`${boardInfo.apiContentsCount}${params ? `?` + params : ``}`)
        .then((response) => {
          const data = response.data.data
          data.totalCount === 0 && type === `STUDY`
            ? setTotalPageSize(itemCount)
            : setTotalPageSize(data.totalCount)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    [communitySeq, searchType, searchWord, boardInfo.apiContentsCount, type]
  )

  // [API] contentsList 호출
  const getContentsList = useCallback(() => {
    const params = queryString.stringify(
      {
        pageIndex: pageIndex,
        pageSize: pageSize,
        communitySeq: type === 'SRR' ? seqSRR : null,
        searchType: searchType,
        searchWord: searchWord
      },
      { skipNull: true }
    )
    api
      .get(boardInfo.apiContentsList + (params ? `?` + params : ``))
      .then((response) => {
        setLoading(false)
        if (response.data.data) {
          const data = response.data.data
          const dataList = type === `STUDY` ? data.questions : data.posts
          setContentsData(dataList)
          getTotalPage(dataList.length)
        } else {
          setContentsData([])
          getTotalPage()
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }, [
    boardInfo.apiContentsList,
    pageIndex,
    pageSize,
    searchType,
    searchWord,
    seqSRR,
    getTotalPage,
    type
  ])

  useEffect(() => {
    if (!pageIndex) {
      // 페이지 넘버 없을 경우 현재 url replace
      navigation(`/community/${type.toLowerCase()}/list?pageIndex=1`, { replace: true })
    } else {
      // 페이지 넘버 있을 경우 목록 가져오기
      getContentsList()
    }
  }, [getContentsList, navigation, pageIndex, type])

  useEffect(() => {
    // 진입시 목록 유형에 맞게 검색어 타입 불러오기
    getSearchSelectList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  return (
    <>
      <BoardBanner
        title={boardInfo.title}
        contents={boardInfo.contents}
        bgFullbox={boardInfo.bgFullbox}
        bgInnerBox={boardInfo.bgInnerBox}
      />
      {deviceCheck ? <InfoTextBox text={boardInfo.infoText} /> : null}
      <div className={styles.smInnerBox}>
        {isLoading ? (
          <BoardSkeleton />
        ) : (
          <div className={styles.boardBox}>
            {type === 'SRR' && <TabBoard activeTab="LIST" />}
            <div className={styles.boardInfo}>
              {deviceCheck ? null : <p>{boardInfo.infoText}</p>}
            </div>
            <div className={styles.boardList}>
              <ul>
                {contentsData && contentsData.length <= 0 ? (
                  <li className={`${styles.noContent} ${isSearchMode && styles.searchMode}`}>
                    <NoContent
                      icoImg={icoImg + (isSearchMode ? 'ico_no_search.png' : 'ico_no_qna.png')}
                      titleMsg={isSearchMode ? `검색 결과가 없습니다.` : boardInfo.noContentText}
                      style={{ paddingBlock: '10rem' }}
                    />
                  </li>
                ) : (
                  contentsData.map((item, idx) => {
                    const registDate = moment(item.registDate).format('YYYY.MM.DD')
                    const subTextTitle =
                      type === `STUDY` ? (
                        item.classes?.className
                      ) : type === `SRR` ? (
                        <span> {registDate}</span>
                      ) : type === `FREE` ? (
                        item.community?.title
                      ) : null
                    return (
                      <BoardListItem
                        key={idx}
                        best={item.isBest}
                        contents={item.title}
                        userName={item.registUser.nickName}
                        subTextDate={type === `FREE` ? <em> {registDate}</em> : ``}
                        link={`/community/${type.toLowerCase()}/detail/${
                          type === `STUDY` ? item.questionSeq : item.communityPostSeq
                        }${location.search}`}
                        subTextTitle={subTextTitle}
                        subInfo={
                          type === `STUDY` ? (
                            <>
                              {item.isReply ? <em>답변완료 </em> : ``}
                              {registDate}
                            </>
                          ) : (
                            <>
                              <span>조회수 {addComma(Number(item.hitCount))}</span>
                              <span>좋아요 {addComma(Number(item.likeCount))}</span>
                              <span>댓글 {addComma(Number(item.commentCount))}</span>
                            </>
                          )
                        }
                      />
                    )
                  })
                )}
              </ul>
            </div>
            {contentsData && contentsData.length > 0 ? (
              <Pagination
                type={'IDX'}
                handleCurrentIdx={onChangePagination}
                totalPages={Math.ceil(totalPageSize / pageSize)}
                limitPage={5}
                maxNum={5}
                minNum={0}
                currentSeq={pageIndex}
                pageIdx={pageIndex}
                jumpingPrevBtn={true}
                jumpingNextBtn={true}
              />
            ) : null}
            {contentsData && contentsData.length <= 0 && !isSearchMode ? null : (
              <BoardSearch
                onChangeFilter={onChangeFilter}
                searchTypeList={searchSelect}
                searchWord={searchWord || ''}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}
