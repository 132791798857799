import { useCallback, useEffect, useRef, useState } from 'react'

import useScript from 'hooks/useScript'
import useSpeechToText from 'hooks/useSpeechToText'

import useLevelTestAiStore from 'store/useLevelTestAiStore'

import { levelTestAiValues } from '../config'
import useLevelTestAiCheckLimitation from './useLevelTestAiCheckLimitation'

export default function useLevelTestAiKlleon(onFailLoadAvatar) {
  const KlleonChat = window.KlleonChat
  const avatarRef = useRef(null)

  const { handleRecognition } = useSpeechToText()
  const { addLimitation } = useLevelTestAiCheckLimitation()

  const [loading, error] = useScript(levelTestAiValues.klleon.scriptSrc)
  const [minimumTimeCheck, setMinimumTimeCheck] = useState(true)

  const examType = useLevelTestAiStore((state) => state.examType)

  // 클레온 초기 세팅 & 스트리밍 시작
  const initKlleonChat = useCallback(async () => {
    if (KlleonChat) {
      await KlleonChat.init({
        sdk_key: levelTestAiValues.klleon.sdkKey,
        avatar_id: levelTestAiValues.klleon.avatarId[examType],
        enable_microphone: false,
        log_level: 'error',
        voice_code: 'en_us'
      })
    }
  }, [KlleonChat, examType])

  // 스크립트 불러오기 후 로딩이 끝나고 에러가 없을 때 클레온 초기 세팅
  useEffect(() => {
    if (examType === 'conversation') {
      handleRecognition.start()
    }
    if (!loading && !error && minimumTimeCheck) {
      initKlleonChat()
    }
  }, [loading, error, initKlleonChat, examType, handleRecognition, minimumTimeCheck])

  useEffect(() => {
    // 아바타 스트리밍 스타일 설정
    if (avatarRef.current) {
      avatarRef.current.videoStyle = {
        objectFit: 'cover'
      }
    }

    return () => {
      KlleonChat?.destroy()
    }
    // 테스트 시작시 횟수 추가
  }, [])

  useEffect(() => {
    const startTime = Date.now()
    let timer = null

    KlleonChat?.onStatusEvent((data) => {
      if (data === 'VIDEO_CAN_PLAY') {
        const elapsedTime = Date.now() - startTime
        const delay = Math.max(3000 - elapsedTime, 0)

        timer = setTimeout(() => {
          setMinimumTimeCheck(false)
          setTimeout(() => {
            addLimitation(examType)
          }, 1000)
        }, delay)
      }
    })

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [KlleonChat, addLimitation, examType])

  // 로딩이 11초되는 시점(10초 초과 시)에 알럿 발생 후 이전 화면 (테스트 시작 전 안내사항)으로 이동
  useEffect(() => {
    let limitTimer = null
    limitTimer = setTimeout(() => {
      if (minimumTimeCheck) {
        alert('오드리 선생님과의 연결이 지연되고 있어요. 잠시 후 다시 시도해 주세요.')
        onFailLoadAvatar()
      }
    }, 10000)

    return () => {
      if (limitTimer) clearTimeout(limitTimer)
    }
  }, [minimumTimeCheck, onFailLoadAvatar])

  return { avatarRef, isLoading: loading || minimumTimeCheck }
}
