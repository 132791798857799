import ChannelTalk from 'components/channelTalk'
import KaKaoTalk from 'components/KaKaoTalk'
import useFloatingElementStore from 'store/useFloatingElementStore'

export default function FloatingButtons() {
  const { floatingElementHeight } = useFloatingElementStore()

  return (
    <div
      style={{
        position: 'fixed',
        right: '0',
        bottom: `${(floatingElementHeight || 0) + 30}px`,
        zIndex: 9999
      }}
    >
      <KaKaoTalk />
      <ChannelTalk />
    </div>
  )
}
