import EnglishHome from 'pages/english/EnglishHome'
import LoginPage from 'pages/login'
import Logout from 'pages/logout'
import Performance from 'pages/performance'
import CategoryList from 'pages/product/categoryList/index'
import CourseList from 'pages/product/CourseList'

import PublicRouter from 'common/publicRouter'

const MainRoutes = [
  { path: '/', element: <EnglishHome /> },

  {
    path: '/login',
    element: (
      <PublicRouter>
        <LoginPage />
      </PublicRouter>
    )
  },
  { path: '/logout', element: <Logout /> },
  { path: '/courseList', element: <CourseList /> },
  { path: '/categoryList', element: <CategoryList /> },
  { path: '/performance/:performanceSeq', element: <Performance /> }
]

export default MainRoutes
