import { useState, useLayoutEffect, useCallback } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import api from 'common/api'
import * as config from 'common/config'
import useHeaderStore from 'store/useHeaderStore'

export default function useEventDetail() {
  const { currentSeq } = useParams()
  const { state } = useLocation()
  const banner = state && state.bannerImage

  const onChangeIsMain = useHeaderStore((state) => state.onChangeIsMain)
  const onChangeCurrentSubIndex = useHeaderStore((state) => state.onChangeCurrentSubIndex)

  const [eventData, setEventData] = useState(null)

  // 어드민에서 설정한 헤더로 노출
  const onChangeHeader = useCallback(
    (category) => {
      switch (category) {
        case 'HOME':
        case 'ENGLISH':
          onChangeCurrentSubIndex(0)
          onChangeIsMain(false)
          break
        case 'CLASS':
          onChangeCurrentSubIndex(2)
          onChangeIsMain(false)
          break

        default:
          onChangeCurrentSubIndex(1)
          onChangeIsMain(false)
          break
      }
    },
    [onChangeCurrentSubIndex, onChangeIsMain]
  )

  const getReviewData = useCallback(() => {
    api
      .get(`/v2/event/${currentSeq}`)
      .then((response) => {
        setEventData(response.data.data)
        onChangeHeader(response.data.data.category)
        console.log('🚀 response.data.data:', response.data.data)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }, [currentSeq, onChangeHeader])

  useLayoutEffect(() => {
    getReviewData()
  }, [currentSeq, getReviewData])

  return {
    banner,
    currentSeq,
    eventData,
    eventStatus: eventData?.eventStatus,
    eventContent: eventData?.eventContent
  }
}
