import { Suspense, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router'

import useGetEventPopup from 'hooks/useGetEventPopup'
import useInitialApp from 'hooks/useInitialApp'

import { getCookie, setCookieForSubDomain } from 'common/Cookie'
import Loading from 'components/common/Loading'
import FloatingButtons from 'components/FloatingButtons'
import Footer from 'components/Footer'
import Header from 'components/header'
import { headerValues } from 'components/header/config'
import PromotionLandingKeyVisual from 'components/promotion/keyVisualBanner/index.jsx'
import ModalPromotionEvent from 'components/promotion/modal/ModalPromotionEvent'
import useHeaderStore from 'store/useHeaderStore'

import routerValues from './config'
import styles from './router.module.scss'
export function Order() {
  return (
    <div>
      <h2 className="h2">Order</h2>
      <Outlet />
    </div>
  )
}

export function Mypage() {
  return (
    <div>
      <h2 className="h2">Mypage</h2>
      {/* <Lnb /> */}
      <Outlet />
    </div>
  )
}

export function Myclass() {
  return (
    <div>
      <h2 className="h2">Myclass</h2>
      {/* <Lnb /> */}
      <Outlet />
    </div>
  )
}

export function Service() {
  return (
    <div>
      <h2 className="h2" aria-hidden="true" style={{ display: 'none' }}>
        Service
      </h2>
      {/* <Lnb /> */}
      <Outlet />
    </div>
  )
}

export function Event() {
  return (
    <div>
      <h2 className="h2">Event</h2>
      <Outlet />
    </div>
  )
}

export function Member() {
  return (
    <div>
      <h2 className="h2">Member</h2>
      <Outlet />
    </div>
  )
}

export function Community() {
  return (
    <div>
      <h2 className="h2">Community</h2>
      <Outlet />
    </div>
  )
}

export function ClassStore() {
  const location = useLocation()
  const popupInfo = useGetEventPopup(location.pathname)

  return (
    <>
      <div>
        <h2 className="h2">Yanadoo Class</h2>
        <Outlet />
      </div>
      {location.pathname !== '/promotion-landing/newBenefit' && popupInfo && (
        <ModalPromotionEvent popupInfo={popupInfo} />
      )}
    </>
  )
}

export function PromotionLanding() {
  const location = useLocation()
  const popupInfo = useGetEventPopup(location.pathname)
  const isH2Hide = routerValues.h2HidePaths.some((path) =>
    location.pathname.toLowerCase().includes(path.toLowerCase())
  )

  useEffect(() => {
    if (!window.CallMtm) {
      window.CallMtm = function () {
        window.CallMtm.q = window.CallMtm.q || []
        window.CallMtm.q.push(arguments)
      }
    }
    window.CallMtm()
  }, [location.pathname])

  return (
    <>
      <div>
        {!isH2Hide && <h2 className="h2">Yanadoo English</h2>}
        <Outlet />
      </div>
      {location.pathname !== '/promotion-landing/newBenefit' && popupInfo && (
        <ModalPromotionEvent popupInfo={popupInfo} />
      )}
    </>
  )
}

export function DefaultLayout() {
  const location = useLocation()
  const isHeaderHide = headerValues.headerHidePaths.some((path) =>
    location.pathname.toLowerCase().includes(path.toLowerCase())
  )

  useInitialApp()

  const onChangeIsMain = useHeaderStore((state) => state.onChangeIsMain)
  const onChangeCurrentSubIndex = useHeaderStore((state) => state.onChangeCurrentSubIndex)
  const isStore =
    location.pathname.toLowerCase().includes('/store/') ||
    location.pathname.toLowerCase().includes('/search-result')
  const isEvent = location.pathname.toLowerCase().includes('/event/')
  const isYafitGnb = getCookie('isYafitGnb') === 'Y'

  useEffect(() => {
    onChangeIsMain(false)

    if (isEvent && isYafitGnb) {
      onChangeCurrentSubIndex(1)
    } else {
      if (isStore) {
        onChangeCurrentSubIndex(2)
      } else {
        onChangeCurrentSubIndex(0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStore, isEvent, isYafitGnb, location.pathname])

  useEffect(() => {
    if (!isEvent && isYafitGnb) {
      setCookieForSubDomain('isYafitGnb', 'N', 1)
    }
  }, [location.pathname, isEvent, isYafitGnb])

  return (
    <>
      {!isHeaderHide && <Header />}
      <Suspense fallback={<Loading />}>
        <div className={styles.container}>
          {/* 프로모션 랜딩일 경우 공통 키비주얼 배너 */}
          <PromotionLandingKeyVisual />
          <Outlet />
        </div>
      </Suspense>
      <Footer />
      <FloatingButtons />
    </>
  )
}
