import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import api from 'common/api'
import { YANADOO_AI_HOST } from 'common/config'

import useGetUser from './useGetUser'

const isEmpty = (val) => {
  if (val === undefined || val === null) return true

  if (typeof val === 'string' || Array.isArray(val)) {
    return val.length === 0
  }

  if (typeof val === 'object') {
    return Object.keys(val).length === 0
  }

  return false
}

export default function useGetAiMessage({ character, mode, mute }) {
  const [feed, setFeed] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useGetUser()
  const [searchParams] = useSearchParams()
  const userSeq = isEmpty(user) ? searchParams.get('seq') : user.userSeq

  const addedFeed = useCallback(
    (message) => {
      if (message.type === 'User') {
        setIsLoading(true)
      }
      setFeed([
        ...feed,
        { type: message.type, msg: message.msg, mode: mode === 'audio' && !mute ? 'audio' : 'chat' }
      ])
    },
    [feed, mode, mute]
  )

  const postMessage = async ({ userSeq, message }) => {
    if (!message || message.trim() === '') return

    const dataBody = {
      id: '',
      userSeq,
      model: 'gpt-3.5-turbo',
      promptType: character,
      message: message.trim()
    }
    await api
      .post(`${YANADOO_AI_HOST}/conversation`, dataBody)
      .then((response) => {
        const data = { type: 'Ai', msg: response.data }
        addedFeed(data)
      })
      .catch((e) => {
        alert('연결이 불안정 합니다. 다시한번 질문해 주세요~')
        console.log('🚀  error:', e)
      })

    setIsLoading(false)
  }

  useEffect(() => {
    if (feed.length > 0 && feed[feed.length - 1].type === 'User' && character !== null) {
      postMessage({ userSeq: userSeq, message: feed[feed.length - 1].msg })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feed, character])

  return { feed, isLoading, addedFeed, postMessage }
}
