import styles from './ImagesUpload.module.scss'

export default function ImagesUpload({ fileImages = [], fileLimit, setFileImages }) {
  const FILE_LIMIT = fileLimit || 5

  const handleImageUpload = (e) => {
    const fileArr = Array.from(e.target.files)
    const totalImages = fileArr.length + fileImages?.length || 0

    if (totalImages > FILE_LIMIT) {
      alert(`이미지는 최대 ${FILE_LIMIT}장까지만 등록 가능합니다.`)
      return
    }
    setFileImages([...fileImages, ...fileArr])
  }

  const handleDeleteImage = (url) => {
    setFileImages((prev) => prev.filter((file) => file !== url))
  }

  return (
    <div className={styles.container}>
      <label className={styles.attachButton} htmlFor="select-image-file">
        <IconUpload />
        사진 첨부하기
        <input
          type="file"
          accept="image/*"
          name="select-image-file"
          id="select-image-file"
          multiple
          onChange={handleImageUpload}
        />
      </label>
      {fileImages?.length > 0 && (
        <ul className={styles.imageList}>
          {fileImages.map((file, index) => (
            <li key={index}>
              <img
                src={file?.thumbnailPath || file.attachmentPath || URL.createObjectURL(file) || ''}
                alt={`Preview ${index}`}
              />
              <button
                type="button"
                className={styles.deleteButton}
                onClick={() => handleDeleteImage(file)}
              >
                <IconDelete />
              </button>
            </li>
          ))}
        </ul>
      )}
      <span className={styles.infoDescription}>
        파일은 2MB 이하, jpg/png/gif 형식 최대 5개까지 등록 가능합니다.
      </span>
    </div>
  )
}

const IconUpload = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
      <rect
        x="5.33203"
        y="6.08594"
        width="21.3333"
        height="21.3333"
        rx="2"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M11.6654 14.7526C12.954 14.7526 13.9987 13.7079 13.9987 12.4193C13.9987 11.1306 12.954 10.0859 11.6654 10.0859C10.3767 10.0859 9.33203 11.1306 9.33203 12.4193C9.33203 13.7079 10.3767 14.7526 11.6654 14.7526Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6654 20.4635L23.2957 17.3712C22.1058 16.2791 20.2649 16.3251 19.131 17.4752L9.33203 27.4141"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const IconDelete = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0013 3.66406C32.1265 3.66406 40.3346 11.8722 40.3346 21.9974C40.3346 32.1226 32.1265 40.3307 22.0013 40.3307C11.8761 40.3307 3.66797 32.1226 3.66797 21.9974C3.66797 11.8722 11.8761 3.66406 22.0013 3.66406Z"
        fill="black"
        fillOpacity="0.8"
      />
      <path
        d="M16.5 16.5L27.5 27.5M27.5 16.5L16.5 27.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
