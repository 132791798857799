import React, { useState, useEffect } from 'react'

import ReviewThumbs from 'pages/community/components/ReviewThumbs'
import { convertHtmlCode } from 'utils/convertHtmlCode'

import api from 'common/api'

import AudioPlayer from './AudioPlayer'
import styles from './Comment.module.scss'
import FileDownLoad from './FileDownLoad'
import ThumbNail from './ThumbNail'
import { timeCheck } from '../../common/common'

export default function Comment({ deviceCheck, data, commentRegistTime, isLoading }) {
  const [replyData, setReplyData] = useState(null)

  // 대댓글 조회 API
  const getReplyData = (seq) => {
    api
      .get(
        `/v2/community-tab/community/comment/child-list?pageIndex=1&pageSize=100&parentSeq=${seq}`
      )
      .then((response) => {
        if (response.data.meta.code === 200) {
          setReplyData(response.data.data.childComments)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (isLoading && data.existChildComment) {
      getReplyData(data.commentSeq)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <>
      <div className={styles.commentBox}>
        <div className={styles.profileBox}>
          <ThumbNail
            backgroundImage={data.registUser.profileImageUrl}
            size={deviceCheck ? '8.2rem' : '5.4rem'}
          />
          <p>
            <strong>{data.registUser.nickName}</strong>
            <span>
              좋아요 {data.likeCount} &middot;{' '}
              {timeCheck(
                commentRegistTime,
                data.registDate.replace('-', '/').replace('-', '/')
              ).replace(/\//g, '.')}
            </span>
          </p>
        </div>
        <div className={styles.descBox}>
          <p>{convertHtmlCode(data.contents)}</p>
          {data.attachment?.type === 'ALL' ? (
            <div className={styles.attachmentBox}>
              <FileDownLoad data={data.attachment} />
            </div>
          ) : undefined}
          {data.attachment?.type === 'AUDIO' ? (
            <div className={styles.attachmentBox}>
              <AudioPlayer data={data.attachment} />
            </div>
          ) : undefined}
          {data.attachment?.type === 'IMAGE' ? (
            <div className={styles.reviewThumbsBox}>
              <ReviewThumbs item={data.attachment} comment />
            </div>
          ) : undefined}
        </div>
      </div>
      {data.existChildComment &&
        replyData &&
        replyData
          .filter((reReply) => reReply.status !== 'DELETE_OF_WRITER')
          .map((reReply, idx) => {
            return (
              <div className={`${styles.commentBox} ${styles.reply}`} key={idx}>
                <div className={styles.profileBox}>
                  <ThumbNail
                    backgroundImage={reReply.registUser.profileImageUrl}
                    size={deviceCheck ? '7.4rem' : '4.8rem'}
                  />
                  <p>
                    <strong>{reReply.registUser.nickName}</strong>
                    <span>
                      좋아요 {reReply.likeCount} &middot;{' '}
                      {timeCheck(
                        commentRegistTime,
                        reReply.registDate.replace('-', '/').replace('-', '/')
                      ).replace(/\//g, '.')}
                    </span>
                  </p>
                </div>
                <div className={styles.descBox}>
                  <p>{reReply.contents}</p>
                  {reReply.attachment?.type === 'ALL' ? (
                    <FileDownLoad data={reReply.attachment} />
                  ) : undefined}
                  {reReply.attachment?.type === 'AUDIO' ? (
                    <AudioPlayer data={reReply.attachment} />
                  ) : undefined}
                  {reReply.attachment?.type === 'IMAGE' ? (
                    <div className={styles.reviewThumbsBox}>
                      <ReviewThumbs item={reReply.attachment} comment />
                    </div>
                  ) : undefined}
                </div>
              </div>
            )
          })}
    </>
  )
}
