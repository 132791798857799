import { isMobile } from 'react-device-detect'
import TagManager from 'react-gtm-module'
import { useNavigate } from 'react-router'

import classNames from 'classnames'
import useLevelTestAiCheckLimitation from 'pages/levelTestAi/exam/hooks/useLevelTestAiCheckLimitation'

import { levelTestAiValues } from 'pages/levelTestAi/exam/config'

import { levelTestAiStartValues } from '../config'
import styles from '../styles/ButtonSection.module.scss'
export default function ButtonSection() {
  const navigation = useNavigate()

  const { checkLimitation } = useLevelTestAiCheckLimitation()

  const onClickStart = (type, name) => {
    checkLimitation(type, () => {
      navigation(`/levelTestAi/exam?examType=${type}`)
    })

    const eventName = {
      quiz: 'ai_leveltest_start_quiz',
      conversation: 'ai_leveltest_start_conversation'
    }[type]

    // window.dataLayer.push({
    //   event: eventName,
    //   product_name: `AI레벨테스트_메인_${name}_시작_버튼`,
    //   button_id: `levelTestlAi-start-${type}`
    // })

    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        product_name: `AI레벨테스트_메인_${name}_시작_버튼`,
        button_id: `levelTestlAi-start-${type}`
      }
    })
  }

  return (
    <div className={styles.container}>
      <p className={styles.description}>
        <img
          src={`${levelTestAiValues.HOST_CDN}image-start-text-anyone.png`}
          alt="Anyone can do it"
        />
      </p>
      <p className={styles.title}>
        야나두 회원은 누구나
        <br />
        1:1 학습 진단 무료!
      </p>
      <ul className={styles.list}>
        {levelTestAiStartValues.buttonList.map((item, index) => (
          <li key={index}>
            <img src={`${levelTestAiValues.HOST_CDN}${item.image}`} alt={item.name} />
            <div className={styles.textWrap}>
              <p className={styles.name}>{item.name} 레벨테스트</p>
              <p className={styles.time}>약 {item.time}분 소요</p>
              <div className={styles.checkWrap}>
                {item.checkList.map((check, index) => (
                  <p key={index} className={styles.check}>
                    <img
                      src={`${levelTestAiValues.HOST_CDN}icon-check-${item.type}.png`}
                      alt="check"
                    />
                    {check}
                  </p>
                ))}
              </div>
              {isMobile && (
                <button
                  id={`levelTestlAi-start-${item.type}`}
                  className={classNames(styles.button, styles[item.type])}
                  onClick={() => onClickStart(item.type, item.name)}
                >
                  {item.name}으로 시작하기
                </button>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
