import { useCallback, useState, useRef, useEffect } from 'react'

import api from 'common/api'
import * as config from 'common/config'

import AlertModal from './AlertModal'
import ModalBtn from './ModalBtn'
import ModalPortal from '../../ModalPortal'
import '../../assets/styles/modal.scss'
import '../../assets/styles/coupon.scss'

export default function ModalEditProfile({ btnClose, userInfo, handleCloseModal }) {
  const nicknameRef = useRef()
  const getUserInfo = JSON.parse(localStorage.getItem('user'))

  const [modalopenAlert, setModalopenAlert] = useState(false)
  const [modalopenImgSelect, setModalopenImgSelect] = useState(false)
  const [defaultProfileList, setDefaultProfileList] = useState(null)
  const [userProfileImg, setUserProfileImg] = useState(userInfo.profileImageUrl)
  const [userNickName, setUserNickName] = useState(userInfo.nickName)
  const [thumbList, setThumbList] = useState('')
  const [selectImgUrl, setSelectImgUrl] = useState()
  const [randomNicknameValue, setRandomNicknameValue] = useState(null)
  const [randomStatus, setRandomStatus] = useState(null)
  const [nicknameValue, setNicknameValue] = useState('')
  const [valueLength, setValueLength] = useState('')
  const [validStatus, setValidStatus] = useState(true)
  const [validText, setValidText] = useState(
    '최소 2자이상~최대 14자 이하(공백 포함) \n 특수문자, 이모지 사용 불가'
  )

  // const handleOpenModalAlert = useCallback((e) => {
  //   document.body.classList.add('modal-open')
  //   setModalopenAlert(true)
  // }, [])

  const handleCloseModalAlert = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenAlert(false)
  }, [])

  const handleOpenModalSelectProfile = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenImgSelect(true)
  }, [])

  const handleCloseModalSelectProfile = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenImgSelect(false)
  }, [])

  const handleNicknameValue = (e) => {
    let target = e.target

    if (target.value.length > target.maxLength) {
      target.value = target.value.slice(0, target.maxLength)
    }

    if (randomNicknameValue !== target.value) {
      setRandomStatus(false)
      setRandomNicknameValue('')
      setNicknameValue(target.value)
    }

    if (userNickName !== target.value) {
      setUserNickName(target.value)
      setRandomNicknameValue('')
      setNicknameValue(target.value)
    }

    if (target.value) {
      setNicknameValue(target.value)
      setValueLength(target.value.length)
    } else {
      setNicknameValue('')
      setRandomNicknameValue('')
      setValidText('최소 2자이상~최대 14자 이하(공백 포함) \n 특수문자, 이모지 사용 불가')
      setValidStatus(true)
      setRandomStatus(false)
    }
  }

  const handleValidCheck = () => {
    let nicknameRegExp = /^[가-힣a-zA-Z0-9 ]*$/
    let currentValue =
      randomNicknameValue && randomStatus
        ? randomNicknameValue
        : nicknameValue
        ? nicknameValue
        : userNickName

    let blankCheckValue = currentValue.trim()

    if (!nicknameRegExp.test(blankCheckValue)) {
      nicknameRef.current.focus()
      setValidStatus(false)
      // console.log('ff한글/영문/숫자로만 입력해주세요.');

      return false
    }

    if (blankCheckValue.length < 2 || blankCheckValue.length > 16) {
      nicknameRef.current.focus()
      setValidStatus(false)
      // console.log('ff최소 2자 이상~최대 15자 이하로 입력해주세요.');

      return false
    }

    setValidStatus(true)
    return true
  }

  const handleRemoveNickname = () => {
    setNicknameValue('')
    setRandomNicknameValue('')
    setUserNickName('')
    setValidStatus(true)
    setValidText('최소 2자이상~최대 14자 이하(공백 포함) \n 특수문자, 이모지 사용 불가')
    setRandomStatus(null)
  }

  const handleImageUpload = (e) => {
    let file = e.target.files[0]

    let reader = new FileReader()

    reader.onload = () => {
      let fileURLs = reader.result
      setThumbList(fileURLs)
    }

    reader.readAsDataURL(file)

    setSelectImgUrl(file)
  }

  // aws에 이미지 등록
  const postImage = () => {
    const formData = new FormData()

    formData.append('file', selectImgUrl)
    formData.append('resizeType', 'PROFILE')

    api
      .post('/v2/file/image-upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        if (response.data.meta.code !== 200) {
          if (response.data.meta.message) {
            alert(response.data.meta.message)
          } else {
            alert(config.MESSAGE['common-error'])
          }
          return
        }

        setUserProfileImg(response.data.data[0].filePath)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getDefaultProfile = () => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/profile/default-images')
      .then((response) => {
        setDefaultProfileList(response.data.data.images)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleRandomNum = () => {
    setUserProfileImg(defaultProfileList[Math.floor(Math.random() * 5 + 1)])
    handleCloseModalSelectProfile()
  }

  const getRandomNickname = () => {
    setValidText('최소 2자이상~최대 14자 이하(공백 포함) \n 특수문자, 이모지 사용 불가')
    setValidStatus(true)

    api
      .get(config.AUTH_API_HOST + '/auth/v2/user/random-nickname')
      .then((response) => {
        setRandomNicknameValue(response.data.data.nickname)
        setValueLength(response.data.data.nickname.length)
        setRandomStatus(true)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const putUserProfile = () => {
    if (!handleValidCheck()) {
      return false
    }

    const data = {
      nickName:
        randomNicknameValue && randomStatus
          ? randomNicknameValue
          : nicknameValue
          ? nicknameValue
          : userNickName,
      profileUrl: userProfileImg
    }

    api
      .put(config.AUTH_API_HOST + '/auth/v2/user/profile', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          nicknameRef.current.focus()
          setValidText(response.data.meta.message)
          setValidStatus(false)

          return false
        }

        alert('프로필 변경이 완료되었습니다.')
        getUserInfo.nickName = data.nickName
        getUserInfo.profileImageUrl = data.profileUrl
        localStorage.setItem('user', JSON.stringify(getUserInfo))
        window.location.reload()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (selectImgUrl) {
      postImage()
    }
  }, [selectImgUrl])

  useEffect(() => {
    getDefaultProfile()
  }, [])

  return (
    <>
      <div className="modal-wrap profile">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <i className="blind">프로필 수정</i>
          </div>
          <div className="modal-content register">
            <div className="profile-edit-col">
              <p>
                프로필 사진을 등록하고
                <br />
                닉네임을 알려주세요!
              </p>
              <p>
                <span>닉네임을 직접 입력·수정하고 생성할 수 있습니다.</span>
              </p>
              {/* <button className="user-thumb" onClick={handleOpenModal3}>
                <i className="blind">썸네일</i>
              </button> */}
              <div
                className="user-thumb"
                style={{
                  backgroundImage: `url(${userProfileImg})`
                }}
              >
                <i className="blind">썸네일</i>
                <button type="button" className="register" onClick={handleOpenModalSelectProfile}>
                  <i className="blind">이미지 등록</i>
                </button>
                <button type="button" className="delete" onClick={handleOpenModalSelectProfile}>
                  <i className="blind">이미지 삭제</i>
                </button>
              </div>
              <div className="profile-input-box">
                {randomNicknameValue && randomStatus && <span>추천 닉네임</span>}
                <input
                  type="text"
                  placeholder="닉네임을 적어주세요."
                  maxLength="14"
                  name=""
                  ref={nicknameRef}
                  value={
                    randomNicknameValue && randomStatus
                      ? randomNicknameValue
                      : nicknameValue
                      ? nicknameValue
                      : userNickName
                  }
                  onChange={handleNicknameValue}
                />
                <div className="valid-box">
                  <p className={'valid-txt' + (validStatus ? '' : ' warning')}>{validText}</p>
                  {nicknameValue || randomNicknameValue ? (
                    <span className="input-length">{valueLength}/14</span>
                  ) : null}
                </div>
                <button type="button" className="btn-remove" onClick={handleRemoveNickname}>
                  <i className="blind">닉네임 지우기</i>
                </button>
              </div>
              <div className="profile-random-box">
                <button type="button" onClick={getRandomNickname}>
                  <span>닉네임 생성하기</span>
                </button>
              </div>
              <div className="btn-flex-form">
                <button
                  className={
                    'btn' +
                    (userNickName || nicknameValue || randomStatus ? ' active' : ' disabled')
                  }
                  disabled={userNickName || nicknameValue || randomStatus ? false : true}
                  onClick={putUserProfile}
                >
                  <span>등록하기</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalopenAlert ? (
        <ModalPortal>
          <AlertModal
            btnGroup
            msg={'저장이 완료되었습니다.'}
            handleCloseModal={handleCloseModalAlert}
          />
        </ModalPortal>
      ) : null}
      {modalopenImgSelect ? (
        <ModalPortal>
          <ModalBtn
            btnClose
            complete={thumbList && thumbList}
            handleCloseModal={handleCloseModalSelectProfile}
            handleImageUpload={handleImageUpload}
            handleRandomNum={handleRandomNum}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
