import React, { useEffect, useRef } from 'react'

import { DotLottie } from '@lottiefiles/dotlottie-web'

// <DotLottiePlayer dataName="keyboard_badge" dataType="lottie" />

export default function DotLottiePlayer({ dataName, dataType }) {
  const canvasRef = useRef(null)
  const lottieData = `/lottie/${dataName}.${dataType}`

  useEffect(() => {
    if (canvasRef.current) {
      new DotLottie({
        autoplay: true,
        loop: true,
        canvas: canvasRef.current,
        src: lottieData // .lottie or .json
      })
    }
  }, [lottieData])

  return <canvas ref={canvasRef} width={1000} height={1000} style={{ maxWidth: '100%' }} />
}
