import classNames from 'classnames'

import useLevelTestAiStore from 'store/useLevelTestAiStore'

import { levelTestAiValues } from '../config'
import ModalMicConfirm from './ModalMicConfirm'
import useLevelTestAiMicPermission from '../hooks/useLevelTestAiMicPermission'
import styles from '../styles/StepNote.module.scss'

export default function StepNote({ onClickCancel, onClickConfirm, onClickMoveToMain }) {
  const examType = useLevelTestAiStore((state) => state.examType)
  const contents = levelTestAiValues.note[examType]
  const { isDisabled, isShowMicConfirmModal, onCloseMicConfirmModal } =
    useLevelTestAiMicPermission()

  return (
    <div className={classNames(styles.container, styles[examType])}>
      <h1 className={styles.title}>{contents?.title}</h1>
      <p className={styles.description}>이런 분에게 딱 맞아요!</p>
      <ul className={styles.targetList}>
        {contents?.list.map((item, index) => (
          <li key={index}>
            <CheckIcon />
            {item}
          </li>
        ))}
      </ul>
      <p className={styles.subDescription}>
        테스트 시작 전 <b>안내사항</b>
      </p>
      <ul className={styles.precautionList}>
        {examType === 'conversation' && (
          <li>
            <span>
              <MicIcon />
              마이크 권한
            </span>
            <p>
              시작 버튼을 누른 후, 마이크 접근 권한에 대한 <br />
              알림창이 뜨면 반드시
              <b>“허용”을 선택해 주세요!</b>
            </p>
          </li>
        )}
        <li>
          <span>
            <VolumeIcon />
            음량 조정
          </span>
          <p>
            AI 오드리가 음성으로 문제를 출제합니다. <b>시작 전에 음량을 조절</b>하면 더 원활하게
            진행할 수 있어요.
          </p>
        </li>
        <li>
          <span>
            <TimerIcon />
            답변 시간 제한
          </span>
          <p>
            보다 정확한 실력 측정을 위해 문항별로 답변 시간이 제한되어 있습니다.
            <b> 시간 내에 답변 해주세요.</b>
          </p>
        </li>
      </ul>
      <div className={styles.buttonWrap}>
        <button onClick={onClickCancel}>이전</button>
        <button disabled={isDisabled} onClick={onClickConfirm}>
          {isDisabled ? (
            <>
              <span>시작 전,</span>마이크 권한 설정이 필요해요.
            </>
          ) : (
            '레벨테스트 시작'
          )}
        </button>
      </div>
      {isShowMicConfirmModal && (
        <ModalMicConfirm
          onClickConfirm={onCloseMicConfirmModal}
          onClickMoveToMain={onClickCancel}
        />
      )}
    </div>
  )
}

const CheckIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#FF6F0F" />
      <path
        d="M11.2461 20.625L16.2461 26.25L28.7461 13.75"
        stroke="white"
        strokeWidth="4.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const MicIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M25.3307 13.3327V15.9993C25.3307 18.4747 24.3474 20.8487 22.5971 22.599C20.8467 24.3494 18.4727 25.3327 15.9974 25.3327M15.9974 25.3327C13.522 25.3327 11.1481 24.3494 9.39773 22.599C7.64739 20.8487 6.66406 18.4747 6.66406 15.9993V13.3327M15.9974 25.3327V29.3327M15.9974 2.66602C14.9365 2.66602 13.9191 3.08744 13.169 3.83759C12.4188 4.58773 11.9974 5.60515 11.9974 6.66602V15.9993C11.9974 17.0602 12.4188 18.0776 13.169 18.8278C13.9191 19.5779 14.9365 19.9993 15.9974 19.9993C17.0583 19.9993 18.0757 19.5779 18.8258 18.8278C19.576 18.0776 19.9974 17.0602 19.9974 15.9993V6.66602C19.9974 5.60515 19.576 4.58773 18.8258 3.83759C18.0757 3.08744 17.0583 2.66602 15.9974 2.66602Z"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
const VolumeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M21.3327 12.0052C22.1982 13.1592 22.666 14.5628 22.666 16.0052C22.666 17.4477 22.1982 18.8513 21.3327 20.0052M25.818 24.4906C26.9323 23.3763 27.8163 22.0534 28.4193 20.5975C29.0224 19.1416 29.3328 17.5811 29.3328 16.0052C29.3328 14.4294 29.0224 12.8689 28.4193 11.413C27.8163 9.95709 26.9323 8.63422 25.818 7.51991M14.666 6.27458C14.6657 6.08886 14.6105 5.90739 14.5072 5.75305C14.4039 5.59872 14.2572 5.47845 14.0856 5.40742C13.914 5.33638 13.7252 5.31777 13.543 5.35393C13.3608 5.39008 13.1935 5.47939 13.062 5.61058L8.55002 10.1212C8.37589 10.2964 8.16873 10.4353 7.94054 10.5298C7.71235 10.6243 7.46767 10.6726 7.22068 10.6719H3.99935C3.64573 10.6719 3.30659 10.8124 3.05654 11.0624C2.80649 11.3125 2.66602 11.6516 2.66602 12.0052V20.0052C2.66602 20.3589 2.80649 20.698 3.05654 20.9481C3.30659 21.1981 3.64573 21.3386 3.99935 21.3386H7.22068C7.46767 21.3379 7.71235 21.3862 7.94054 21.4807C8.16873 21.5752 8.37589 21.7141 8.55002 21.8892L13.0607 26.4012C13.1921 26.533 13.3598 26.6227 13.5423 26.6591C13.7248 26.6955 13.914 26.6768 14.0859 26.6056C14.2578 26.5343 14.4047 26.4137 14.508 26.2588C14.6113 26.104 14.6663 25.922 14.666 25.7359V6.27458Z"
        stroke="white"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const TimerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M15.9968 28.8804C22.0077 28.8804 26.8804 24.0077 26.8804 17.9968C26.8804 11.986 22.0077 7.11328 15.9968 7.11328C9.98601 7.11328 5.11328 11.986 5.11328 17.9968C5.11328 24.0077 9.98601 28.8804 15.9968 28.8804Z"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0273 3.15625H18.9638"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 3.15625V7.11391"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0273 14.0391L15.9956 17.9967H20.9427"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.8906 6.125L27.8695 8.10383"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.10383 6.125L4.125 8.10383"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
