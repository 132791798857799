import useOnClickOutside from 'hooks/useOnClickOutside'
import ModalPortal from 'ModalPortal'

import s from './ModalWrap.module.css'

export default function ModalWrap({ btn = false, children, onClose, dateClose, width }) {
  const modalRef = useOnClickOutside(onClose)

  return (
    <ModalPortal>
      <article className={s.modalWrap}>
        <div
          className={`${s.modalContent} modalContent`}
          style={{ maxWidth: width ? width : '100%' }}
          ref={modalRef}
        >
          {btn && (
            <button className={`${s.btnClose} btnClose`} onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4 4L20 20M20 4L4 20"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}
          {children}
          {dateClose && (
            <button className={s.btnTodayClose} onClick={dateClose.onClick}>
              {dateClose.title}
            </button>
          )}
        </div>
      </article>
    </ModalPortal>
  )
}
