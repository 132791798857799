import TagManager from 'react-gtm-module'
/**
 * GA 이벤트 호출용 함수
 */
export const postGAEvent = (event, productName) => {
  if (event && productName) {
    TagManager.dataLayer({
      dataLayer: {
        event,
        product_name: productName
      }
    })
  }
}
